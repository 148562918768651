import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  NavLink,
} from "react-router-dom";
import { BsFillSunFill } from "react-icons/bs";
import { MdDarkMode } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { FaChevronCircleLeft, FaChevronCircleRight, FaRegUser } from "react-icons/fa";
import { RxDashboard } from "react-icons/rx";
import { RiTeamFill } from "react-icons/ri";


import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import GridViewIcon from "@mui/icons-material/GridView";
import Person2Icon from "@mui/icons-material/Person2";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import GroupsIcon from "@mui/icons-material/Groups";
import BusinessIcon from "@mui/icons-material/Business";
import GradeIcon from '@mui/icons-material/Grade';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { LockClockOutlined } from "@mui/icons-material";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LogoutIcon from '@mui/icons-material/Logout';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import {  Grid, Modal } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

// View Imports

export const UserSideBar = ({ open, setOpen, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [openShareModal, setOpenShareModal] = useState(false)
  const userDetails = useSelector(state => state.userReducer)

  const route = [
    {
      name: "Dashboard",
      path: "/",
      icon:<GridViewIcon className=""/>
    },
    {
      name: "Profile",
      path: "/profile",
      icon:<Person2Icon className=""/>
    },
    {
      name:'Team List',
      icon:<GroupsIcon className=""/>,
      toggleStatus:false,
      dropDown:[
        {
          path:'/teamList/referals',
          name:'Referals'
        },
        {
          path:'/teamList/list',
          name:'Team List'
        },
        {
          path:'/teamList/listwithbussiness',
          name:'Team Bussiness'
        }
      ]
    },
    {
      name:'Business History',
      path:'/businessHistory',
      icon:<BusinessIcon className=""/>
    },
    {
      name:'Income Details',
      icon:<MonetizationOnIcon className=""/>,
      dropDown:[
        {
          path:'/totalIncome',
          name:'Total Income'
        },
        {
          path:'/directIncome',
          name:'Direct Income'
        },
        {
          path:'/levelIncome',
          name:'Level Income'
        },

      ]
    },
    {
      name:'Transaction History',
      path:'/transactionHistory',
      icon:<GradeIcon className=""/>
    },
    {
      name:'Member Tree',
      path:'/memberTree',
      icon:<ReceiptLongIcon className=""/>
    },
    {
      name:'Change Passcode',
      path:'/changePassword',
      icon:<LockClockOutlined className=""/>
    },
    {
      name:'Campaign',
      path:'/campaign',
      icon:<EmojiEventsIcon className=""/>
    },
  ];



  const applyTheme = (theme) => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };
  
  const toggleTheme = () => {
    const currentTheme = document.documentElement.classList.contains("dark")
      ? "dark"
      : "light";
    const newTheme = currentTheme === "dark" ? "light" : "dark";
  
    localStorage.setItem("color-theme", newTheme);
    applyTheme(newTheme);
  };
  
  // Check for stored theme preference
  const storedTheme = localStorage.getItem("color-theme");
  
  // If there is a stored theme, apply it; otherwise, default to dark mode
  // if (!storedTheme) {
    applyTheme(storedTheme);
  

  // Access the pathname from location
  var currentPathname = location.pathname;

  const [width, setWidth] = useState(window.innerWidth);
  const [selectedIndex,setSelectedIndex]=useState(null)

  const routeToggle = (ind) => {

    if(ind==2||ind==4){
    if(selectedIndex){
      setSelectedIndex(null)
    }
    else{
      setSelectedIndex(ind)
    }
  }
    if (width < 1024) {
      setOpen(false);
    } else {
      console.log("pom");
    }
  };

  const logoutHandler = () => {
    localStorage.removeItem("token");
    // emitter.emit("logout");
  };


  const handleSignOutButton = () => {
    try {
      dispatch({
        type: "CLEAR_TOKEN",
      });
      navigate("/login");
      localStorage.clear()
      window.location.reload();
    } catch (error) {}
  };


  return (
    <div className="">

      {
        <nav className=" fixed mr-64 w-full bg-white  dark:bg-darktheme text-black dark:text-white   z-40 ">
          <div className="px-3 py-3 lg:px-6 lg:pl-3">
            <div className="flex items-center justify-between ">
              {/* <button onClick={() => setOpen(true)}>
                <GiHamburgerMenu className="text-theme text-3xl" />
              </button> */}

              <div className="flex items-center justify-start">
                <button
                  onClick={() => setOpen(!open)}
                  // data-drawer-target="logo-sidebar"
                  // data-drawer-toggle="logo-sidebar"
                  // aria-controls="logo-sidebar"
                  type="button"
                  className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg  focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 "
                >
                  <span className="sr-only">Open sidebar</span>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      fillRule="evenodd"
                      d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                    ></path>
                  </svg>
                </button>
                <a href="https://flowbite.com" className="flex ml-2 md:mr-24">
                  {/* <img src="https://flowbite.com/docs/images/logo.svg" className="h-8 mr-3" alt="FlowBite Logo" /> */}
                  {!open && (
                    <span className="self-center text-xl font-medium text-darkbgColor whitespace-nowrap dark:text-red-100">
                      Zepex
                    </span>
                  )}
                </a>
              </div>
              <div className="flex items-center">
                <div className="flex items-center ml-3">
                  <button className="mx-5" onClick={()=>toggleTheme()}>
                    <div className="hidden dark:flex text-white">
                      <BsFillSunFill className="text-2xl" />
                    </div>
                    <div className="dark:hidden visible">
                      <MdDarkMode className="text-2xl text-black" />
                    </div>
                  </button>
                  <div>
                  
                    <button
                    onClick={() => { setOpenShareModal(true) }}
                     className="border p-2 px-3 rounded-md text-w shadow-lg bg-darkbgColor text-white">
                        Reffer & Earn
                    </button>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </nav>
      }

      {
        <aside
          id="logo-sidebar"
          className={`fixed   top-0 left-0 z-50 ${
            open ? "w-64" : "w-0 "
          } duration-300 h-screen dark:bg-darktheme bg-white   border-gray-200 lg:translate-x-0 `}
          aria-label="Sidebar"
        >
          <div
            className={`h-full  overflow-y-auto  duration-300  relative ${
              open ? "w-64" : "w-0 px-0"
            }`}
          >
            <div className="mx-6 mt-7 mb-8 w-full flex justify-between relative ">
              <div className="flex items-center ">
                {/* <img className="h-20 w-20" src=""/> */}
                <span className="self-center text-darkbgColor text-xl font-bold sm:text-2xl whitespace-nowrap dark:text-white">
                  Zepex
                </span>
              </div>

              <span
                onClick={() => setOpen(false)}
                className={`self-center z-50  absolute right-10 lg:fixed lg:-right-3 duration-500 ${
                  open ? "lg:visible " : "hidden"
                } duration-300`}
              >
               {!open?<FaChevronCircleLeft />:<FaChevronCircleRight className="text-3xl text-darkbgColor dark:text-white" />}
              </span>
            </div>
            <ul className="space-y-5 font-normal group ">
              {route.map((ele, ind) => {
                return (
                  <li key={ind} className="px-2">
                    <NavLink  onClick={() => routeToggle(ind)} to={ele.path}>
                      <p
                        href="/"
                        className={`flex rounded-lg  dark:text-white px-5 py-2 hover:bg-darktheme/20 ${currentPathname==ele.path?'bg-darkbgColor text-white':''}`}
                      >
                       
                        {ele.icon}
                        <span className="ml-3">{ele.name}</span>
                        {(ind==2||ind==4) &&<>
                        {selectedIndex?<ArrowDropUpIcon/>:<ArrowDropDownIcon/>}
                        </>}
                      </p>
                    </NavLink>

                    <div>
                      {selectedIndex==ind&&
                        ele?.dropDown?.map((item,ind)=>{
                          return(
                            <NavLink  to={item.path}>
                            <p
                        className={`flex rounded-lg  dark:text-white px-5 py-2 hover:bg-darktheme/20 ${currentPathname==item.path?'bg-darkbgColor text-white':''}`}
                        >
                             
                              {/* {?.icon} */}
                              <span className="ml-8">{item.name}</span>
                            </p>
                          </NavLink>
                          )
                        })
                      }
                    </div>
                  </li>
                );
              })}
            </ul>

              <div className="flex justify-center items-center absolute w-full bottom-10">
              <button
              onClick={handleSignOutButton}
               className=" w-[90%] text-white py-2 rounded-lg bg-darkbgColor">
                <LogoutIcon className="mr-3"/>
              Logout
            </button>
              </div>

          
          </div>
        </aside>
      }
      <div className={`${open ? "lg:ml-64" : ""} duration-300 bg-bgColor dark:bg-darkbgColor  pt-[60px]`}>
        {children}
      </div>


      {open&&<div className="bg-black/40 h-[200vh] w-screen absolute lg:hidden top-0"></div>}

      <Modal
            open={openShareModal}
            onClose={() => { setOpenShareModal(false) }}
          >
            <div className="shareModal bg-darktheme shadow-2xl rounded-2xl">
              <h3 className="dark:text-white font-semibold">Share On Social Platforms: </h3><br></br>
              <div className="iconContainer">
                <TwitterShareButton url={`https://zepx.io/register?referralCode=${userDetails.referralId}`}><TwitterIcon size={40} round={true}></TwitterIcon></TwitterShareButton>
                <FacebookShareButton url={`https://zepx.io/register?referralCode=${userDetails.referralId}`}><FacebookIcon size={40} round={true}></FacebookIcon></FacebookShareButton>
                <WhatsappShareButton url={`https://zepx.io/register?referralCode=${userDetails.referralId}`}><WhatsappIcon size={40} round={true}></WhatsappIcon></WhatsappShareButton>
                <TelegramShareButton url={`https://zepx.io/register?referralCode=${userDetails.referralId}`}><TelegramIcon size={40} round={true}></TelegramIcon></TelegramShareButton>
                <EmailShareButton url={`https://zepx.io/register?referralCode=${userDetails.referralId}`}><EmailIcon size={40} round={true}></EmailIcon></EmailShareButton>
              </div>
              <div className="clipboardContainer">
                <p style={{ color: 'white', fontSize: 10 }}>{`https://zepx.io/register?referralCode=${userDetails.referralId}`}</p>
                <p className="text-blue-700 font-bold" style={{  fontSize: 12, cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(`https://zepx.io/register?referralCode=${userDetails.referralId}`) }}>Copy</p>
              </div>
            </div>
          </Modal>
    </div>
    // <Routes>
    // 	<Route path='/' element={<HomeView />} />
    // </Routes>
  );
};