import React from "react";
import Carousel from "react-multi-carousel";
import { BASE_FILE_PATH } from "../../GlobalVariable";
import star1 from "../../assets/1.jpeg";
import star2 from "../../assets/2.jpeg";
import star3 from "../../assets/3.jpeg";
import star4 from "../../assets/4.jpeg";
import star5 from "../../assets/5.jpeg";
import star6 from "../../assets/6.jpg";
import star7 from "../../assets/7.jpg";
import star8 from "../../assets/8.jpg";
import star9 from "../../assets/9.jpg";
import star10 from "../../assets/10.jpg";
import star11 from "../../assets/11.jpg";




function Benifit() {
  const data = [
    {
      tittle: "Star Achiever",
      number: "01",
      image: star1
    },
    {
      tittle: "Sliver Achiever",
      number: "02",
      image: star2
    },
    {
      tittle: "Star Achiever",
      number: "03",
      image: star3
    },
    {
      tittle: "Sliver Achiever",
      number: "04",
      image: star4
    }, 
     {
      tittle: "Star Achiever",
      number: "05",
      image: star5
    },
    {
      tittle: "Star Achiever",
      number: "06",
      image: star6
    },
    {
      tittle: "Star Achiever",
      number: "07",
      image: star7
    },
    {
      tittle: "Star Achiever",
      number: "08",
      image: star8
    },
    {
      tittle: "Star Achiever",
      number: "09",
      image: star9
    },
    {
      tittle: "Star Achiever",
      number: "10",
      image: star10
    },
    {
      tittle: "Star Achiever",
      number: "11",
      image: star11
    },
    {
      tittle: "Star Achiever",
      number: "05",
      image: star5
    },
   
  ];
  return (
    <div    className=" w-screen lg:px-20 mx-auto px-3 lg:p-8 my-10">
      <p className="gradientText text-5xl font-semibold text-center">
        Benefits of Joining ZepxChain
      </p>

      <div>
        <p data-aos="flip-down"  className="text-white font-medium  text-center mt-10 text-xl">
          ZEPXChain is a blockchain protocol similar to BEP-20, designed to
          facilitate the creation and management of digital assets and tokens
          within its ecosystem. Like BEP-20 tokens on the Binance Smart Chain,
          ZEPXChain tokens are compatible and can be utilized for various
          purposes, including decentralized applications, smart contracts, and
          decentralized finance (DeFi) activities. ZEPXChain offers scalability,
          security, and interoperability, making it a versatile platform for
          tokenization and blockchain-based ventures..
        </p>
      </div>

      <div data-aos="flip-down"  className="mt-20">
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay={true}
          autoPlaySpeed={4000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover={false}
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 2, // Only 1 item for desktop
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1, // Only 1 item for mobile
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 1, // Only 1 item for tablet
              partialVisibilityGutter: 30,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {data.map((ele, ind) => {
            return <Card key={ind} item={ele} ind={ind} />;
          })}
        </Carousel>
      </div>
    </div>
  );
}

export default Benifit;

const Card = ({ item }) => {
  return (
    <div
      className={`lg:w-[100%] h-[100%]  flex flex-col justify-center items-center text-center`}
    >
        <img src={item.image} alt={`Card ${item.number}`} 
        className="w-full" 
        style={{   boxShadow: '0px 0px 50px 0px white', 
        borderRadius: '25px',
        
        padding: '6%' }} />
        {/* <p className="text-white font-normal text-2xl lg:text-5xl">{item.title}</p> */}
        {/* <p className="text-white font-medium text-3xl lg:text-4xl">{item.number}</p> */}
    </div>
  );
};
