let statusIntitialState = {
    status: false
}

export const statusReducer = (state = statusIntitialState, action) => {
    switch (action.type) {
        case 'CHANGE_STATUS':
            return {
                ...state,
                status: action.payload,
            };
       

        default:
            return state;
    }
}