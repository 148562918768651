import React, { useEffect } from 'react'
import Home from '../components/publicWebsite/Home'
import Navbar from '../components/publicWebsite/Navbar'
import { InfiniteSlider } from '../components/publicWebsite/InfiniteSlider'
import About from '../components/publicWebsite/About'
import Intro from '../components/publicWebsite/Intro'
import KeyFeature from '../components/publicWebsite/KeyFeature'
import Footre from '../components/publicWebsite/Footre'
import Benifit from '../components/publicWebsite/Benifit'
import UtilizationandAllocation from '../components/publicWebsite/UtilizationandAllocation'

import { animateScroll } from 'react-scroll';
import CurrentValue from '../components/publicWebsite/CurrentValue'

const options = {
  // your options here, for example:
  duration: 500,
  smooth: true,
};


function PublicPage() {


  
  useEffect(() => {
    animateScroll.scrollToTop(options);

  
  }, [])
  
  return (
    <div className='w-screen relative overflow-hidden bg-blueC'>
        <Navbar/>
        <Home/>
        <About/>

        <Intro/>

        <KeyFeature/>
        <UtilizationandAllocation/>
        <Benifit/>
        <CurrentValue/>
        <Footre/>
    </div>
  )
}

export default PublicPage