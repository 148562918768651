import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { getDirectTeamMemberService } from '../../services/userServices';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import Table from './Table';
import { Pagination } from '@mui/material';

const columns = [
    {
        field: 'sl',
        headerName: 'Sr. No.',
        width: 90
    },
    {
        field: 'id',
        headerName: 'Member Id',
        width: 150,
    },
    {
        field: 'referredBy',
        headerName: 'Referred By',
        width: 150,
    },
    {
        field: 'parentId',
        headerName: 'Parent Id'
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 110,
    },
    {
        field: 'joiningAmount',
        headerName: 'Joining Amount',
        width: 110,
    },
    {
        field: 'date',
        headerName: 'Joining Date',
        width: 200
    },
];

export default function DirectTeamList() {

    const getTokenData = useSelector(state => state.tokenReducer)
    const [isLoading, setIsLoading] = useState(false);

    const [incomeDetails, setIncomeDetails] = React.useState([])

    const getIncomeDetails = async () => {
        try {
            setIsLoading(true)
            let res = await getDirectTeamMemberService(getTokenData.token)
            let temp = []
            res.data.result.map((d, i) => {
                temp.push({
                    sl: (i + 1).toString(),
                    id: (d.ownReferralCode).toString(),
                    referredBy: d.referredBy,
                    parentId: d.parentReferralCode,
                    email: d.email,
                    joiningAmount: d.joiningDetails.selectedPlan.toString(),
                    date: new Date(d.joiningDetails.dateTimestamp)
                })
                return d
            })
            setIncomeDetails([...temp])
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getIncomeDetails()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    function CustomPagination() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);
      
        return (
          <Pagination
            className='text-white'
            color="primary"
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
            headerClassName='custom-header-class'
          />
        );
      }


    return (
        <div className='w-full h-[100dvh!important]  flex  px-5 mt-10'>
             <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


            {incomeDetails.length > 0 && 
            <Box className='w-full h-[100%!important] border-none'>

                <DataGrid 
              

                    rows={incomeDetails}
                    columns={columns}
                    pageSize={15}
                    rowsPerPageOptions={[15]}
                    className='bg-white dark:bg-darktheme border-0 text-darktheme dark:text-white w-full h-[80%!important]'
                    components={{
                        Pagination: CustomPagination,
                      }}
                />
            </Box>
            }
        </div>
    );
}
