import AppRoutes from './routes/AppRoutes'
import React, { useEffect } from 'react'
import 'material-react-toastify/dist/ReactToastify.css';
import './index.css';
import Aos from "aos";
import "aos/dist/aos.css"; 
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';


function App() {


  useEffect(() => {
    window.scrollTo(0, 0)
    Aos.init({
      duration: 1000,
      delay:500, // Animation duration (in milliseconds)
      //   once: , // Whether animation should happen only once
      offset:200
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ToastContainer />
      <>
        <AppRoutes />
       
      </>
    </>
  )
}

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

export default App
