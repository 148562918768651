import React from "react";
import { InfiniteSlider, InfiniteSliderO } from "./InfiniteSlider";
import dolor from "../../assets/dolor.png";
import bg1 from "../../assets/bg1.png";
import avtar1 from "../../assets/avtar1.png";

function About() {
  return (
    <div
      id="about"
      style={{ background: `url('${bg1}')`, backgroundSize: "cover" }}
      className="  relative"
    >
      <InfiniteSlider />

      <div className=" flex justify-center flex-col items-center   pt-36 mx-auto  ">
        <p className="gradientText text-3xl lg:text-7xl mb-10 font-bold ">
          ABOUT ZEPEX
        </p>
        

        <div
          data-aos="zoom-in"
          className="border-[#33DA87] rounded-md  w-[90vw] border-theme shadow-xl shadow-blueC mb-10   "
        >
          <div className="w-[100%] justify-center flex-wrap flex flex-row-reverse items-center ml-auto">
            <img
              data-aos="fade-up"
              data-aos-duration="3000"
              className="lg:w-[40%] drop-shadow-2xl shadow-green-500"
              src={avtar1}
            />
            
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="lg:w-[50%] px-3 mb-10 lg:mb-0"
            >
              <p className="text-white font-normal lg:text-3xl mt-5">
              ZEPX is revolutionizing the digital marketplace as a multifaceted cryptocurrency. Designed for versatility, it serves as a universal exchange token across various platforms. In the gaming world, ZEPX acts as an in-game currency, enhancing the gaming experience with seamless transactions. It's also an essential asset for buying and selling digital assets, streamlining the process with its stability and reliability.
              </p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
