let statusIntitialState = {
    openVideoModal: false,
    videoLink: null
}

export const modalReducer = (state = statusIntitialState, action) => {
    switch (action.type) {
        case 'SET_OPENVIDEOMODAL':
            return {
                ...state,
                openVideoModal: action.payload.open,
                videoLink: action.payload.link,
            };


        default:
            return state;
    }
}