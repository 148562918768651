import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
function ProgressBar({rating}) {
  return (
    <div className={`rounded-[50%] w-full h-full text-black `}>
      <CircularProgressbar
        className={`text-[34px] font-[500] fill-[rgba(10,10,14)] relative text-[white!important]  shrink-0 rounded-[50%] w-full h-[130px] `} 
        value={rating}
        maxValue={100}
        text={`${rating}%`}
        styles={buildStyles({
          pathColor:
            rating < 40 ? "#6038CC" : rating < 75 ? "rgb(241, 115, 136)" : "#75E292",
            textColor: "black",
        })}
      />
    </div>
  )
}

export default ProgressBar