import React, { useState } from "react";
import "./Navbar.css";
import { Button, Grid, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from '@mui/icons-material/Menu';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import { getDateAndTimeByTimestamp } from "../../helpers/DateAndTimeFunctions";

export default function Navbar() {
  const userDetails = useSelector(state => state.userReducer)
  const navbarData = useSelector((state) => state.statusReducer)
  const dispatch = useDispatch()
  const [openShareModal, setOpenShareModal] = useState(false)

  const handleMenuButton = () => {
    dispatch({
      type: 'CHANGE_STATUS',
      payload: !navbarData.status
    })
  }


  return (
    <div className="navbar">
      <MenuIcon className="navbar__hamburgerIcon" onClick={() => { handleMenuButton() }} />
      <div>
        <p>Dashboard</p>
      </div>
      <p className="navbar__date">Date Of Joining: {getDateAndTimeByTimestamp(userDetails.doj)}</p>
      {/* <p className="navbar__date">Old Balance: ${userDetails.unclearedAmount.toFixed(2)}</p> */}
      <div>

        <div className="navbar__rightBox">
          <Grid sx={{ display: 'flex', width: '60%', justifyContent: 'space-evenly' }} className="referAndWalletButtons">
            <Grid>
              <Button
                onClick={() => { setOpenShareModal(true) }}
                className="referButton"
              >
                REFER & EARN
              </Button>
              <p className="navbar__rightBox__date"> Date Of Joining: {getDateAndTimeByTimestamp(userDetails.doj)}</p>
              {/* <p className="navbar__rightBox__date"> Old Balance: ${userDetails.unclearedAmount.toFixed(2)}</p> */}

            </Grid>
          </Grid>
          <Modal
            open={openShareModal}
            onClose={() => { setOpenShareModal(false) }}
          >
            <div className="shareModal">
              <h3>Share On Social Platforms: </h3><br></br>
              <div className="iconContainer">
                <TwitterShareButton url={`https://zepx.io/register?referralCode=${userDetails.referralId}`}><TwitterIcon size={40} round={true}></TwitterIcon></TwitterShareButton>
                <FacebookShareButton url={`https://zepx.io/register?referralCode=${userDetails.referralId}`}><FacebookIcon size={40} round={true}></FacebookIcon></FacebookShareButton>
                <WhatsappShareButton url={`https://zepx.io/register?referralCode=${userDetails.referralId}`}><WhatsappIcon size={40} round={true}></WhatsappIcon></WhatsappShareButton>
                <TelegramShareButton url={`https://zepx.io/register?referralCode=${userDetails.referralId}`}><TelegramIcon size={40} round={true}></TelegramIcon></TelegramShareButton>
                <EmailShareButton url={`https://zepx.io/register?referralCode=${userDetails.referralId}`}><EmailIcon size={40} round={true}></EmailIcon></EmailShareButton>
              </div>
              <div className="clipboardContainer">
                <p style={{ color: 'white', fontSize: 10 }}>{`https://zepx.io/register?referralCode=${userDetails.referralId}`}</p>
                <p style={{ color: 'blue', fontSize: 12, cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(`https://zepx.io/register?referralCode=${userDetails.referralId}`) }}>Copy</p>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}
