import React from "react";
import { BsCheck2All } from "react-icons/bs";
import Logo from "../../assets/Logo.png";


// 411501507657
export const InfiniteSlider = () => {
  return (
    <div className="py-2 my-10 bg-purple  mx-auto bg-[#ffffff]/20 rotate-[4deg]  absolute top-3  ">
      <div class="wrapper py-1">
        <div class="slider">
          <div class="slide-track ">
          
          {
            [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,].map((item,ind)=>{
                return(
                    <img key={ind} className="h-8 mx-4" src={Logo}/>
                )
            })
          }
          </div>
        </div>
      </div>
    </div>
  );
};

export const InfiniteSliderO = () => {
  return (
    <div className="py-2 my-10 bg-red  mx-auto bg-[#ffffff]/20 rotate-[-4deg]  absolute top-3  ">
      <div class="wrapper py-1">
        <div class="slider">
          <div class="slide-track ">
          
          {
            [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,].map((item,ind)=>{
                return(
                    <img key={ind} className="h-8 mx-4" src={Logo}/>
                )
            })
          }
          </div>
        </div>
      </div>
    </div>
  );
};
