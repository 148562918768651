import { Backdrop, Button, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import { useDispatch, useSelector } from "react-redux";
import { BASE_FILE_PATH } from "../../GlobalVariable";
import avtar from "../../assets/images/avtar.png";
import updateicon from "../../assets/images/updateicon.png";
import { getDDMMYYYYByTimestamp } from "../../helpers/DateAndTimeFunctions";
import {
  getUserDetailService,
  updateProfileDetailsService,
  updateProfileImageService,
  uploadProfileImageService,
} from "../../services/userServices";
import "./Profile.css";
import { ToastError, ToastSuccess } from "./Toast";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { Modal } from "@mui/material";
import { maxHeight } from "@mui/system";
export default function Profile() {
  const [update, setUpdate] = useState(false);
  const [dob, setDob] = useState(new Date());
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const userDetails = useSelector((state) => state.userReducer);

  const getTokenData = useSelector((state) => state.tokenReducer);
  const dispatch = useDispatch();

  //function to get user details
  const getUserDetails = async () => {
    try {
      setIsLoading(true);
      let res = await getUserDetailService(getTokenData.token);
      dispatch({
        type: "SET_PROFILE_IMAGE",
        payload: res.data.result.image,
      });
      setUserInfo(res.data.result);
      setProfileData(res.data.result);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const setProfileData = (data) => {
    try {
      if (data.dob !== undefined) {
        setDob(new Date(data.dob));
      }
      if (data.name !== undefined && data.name !== "") {
        setName(data.name);
      }
      if (data.address !== undefined && data.address !== "") {
        setAddress(data.address);
      }
      if (data.mobile !== undefined && data.mobile !== "") {
        setMobile(data.mobile);
      }
    } catch (error) {}
  };

  const cancelButtonHandler = () => {
    setUpdate(false);
    setProfileData(userInfo);
  };

  const handleFileUploadButton = async (e) => {
    try {
      setIsLoading(true);
      let res = await uploadProfileImageService(e.target.files[0]);
      let data = {};
      data.imagePath = res.data.path;
      if (
        userDetails.profileImage !== undefined &&
        userDetails.profileImage !== ""
      ) {
        data.removeImage = userDetails.profileImage;
      }
      await updateProfileImageService(data, getTokenData.token);
      setIsLoading(false);
      e.target.value = null;
      getUserDetails();
      ToastSuccess("Profile Picture Updated Successfully.");
    } catch (error) {
      setIsLoading(false);
      ToastError("Something Went Wrong.");
    }
  };

  const updateProfileDetails = async () => {
    try {
      if (address === "" || name === "" || mobile === "") {
        ToastError("Fill All The Field.");
        return;
      }
      setIsLoading(true);
      let data = {};
      data.dob = dob.getTime();
      data.address = address;
      data.name = name;
      data.mobile = mobile;
      await updateProfileDetailsService(data, getTokenData.token);
      getUserDetails();
      setIsLoading(false);
      setUpdate(false);
      ToastSuccess("Profile Updated Successfully.");
    } catch (error) {
      setIsLoading(false);
      ToastError("Something Went Wrong.");
    }
  };

  useEffect(() => {
    getUserDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const func = () => {
    true && (
      <div className="profile">
        <div className="profile__leftBox">
          <div className="profile__leftBox__imgDiv">
            <img
              src={
                userDetails.profileImage !== ""
                  ? BASE_FILE_PATH + userDetails.profileImage
                  : avtar
              }
              alt="Failed To Load"
            />
            {update === true && (
              <input
                type="file"
                onChange={(e) => {
                  handleFileUploadButton(e);
                }}
                accept="image/png, image/gif, image/jpeg"
              ></input>
            )}
          </div>

          <div className="profile__leftBox__personalInfo">
            <div>
              <h3>Personal Info</h3>
            </div>
            {update === false && (
              <img
                src={updateicon}
                alt="Failed To Load"
                onClick={() => setUpdate(true)}
              />
            )}
          </div>

          <div className="profile__leftBox__personalInfo2">
            <div className="profile__leftBox__personalInfo2__birthDiv">
              <p className="profile__leftBox__personalInfo2__birthDiv__bithdateHeading">
                Birthdate:
              </p>
              {update ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="datePicker"
                    value={dob}
                    onChange={(newValue) => {
                      setDob(newValue.$d);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              ) : (
                <p className="profile__leftBox__personalInfo2__birthDiv__date__p">
                  {getDDMMYYYYByTimestamp(dob.getTime())}
                </p>
              )}
            </div>

            <div className="profile__leftBox__personalInfo2__addressDiv">
              <p className="profile__leftBox__personalInfo2__addressDiv__addressHeading">
                Address:
              </p>
              {update ? (
                <textarea
                  className="profile__leftBox__personalInfo2__addressDiv__address"
                  placeholder="Enter Address"
                  rows="5"
                  cols="33"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              ) : (
                <p className="profile__leftBox__personalInfo2__addressDiv__address__p">
                  {address}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="profile__rightBox">
          <div className="profile__rightBox__topDiv">
            {update ? (
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="standard-basic"
                  label="Name"
                  value={name}
                  variant="standard"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Box>
            ) : (
              // <input
              //   className="profile__rightBox__info__mobileDiv__mobile"
              //   placeholder="Enter Your Name"
              //   value={name}
              //   onChange={(e) => { setName(e.target.value) }}
              // />
              <h3>{name}</h3>
            )}
            <p className="profile__rightBox__id">{userDetails.referralId}</p>

            <div className="profile__rightBox__info">
              <div className="profile__rightBox__info__emailDiv">
                <p className="profile__rightBox__info__emailDiv__emailHeading">
                  Email:
                </p>
                <p className="text-theme dark:text-white font-semibold">
                  {userInfo?.email !== undefined ? userInfo.email : "-"}
                </p>
              </div>

              <div className="profile__rightBox__info__mobileDiv">
                <p className="profile__rightBox__info__mobileDiv__mobileheading">
                  mobile:
                </p>
                {update ? (
                  // <input
                  //   className="profile__rightBox__info__mobileDiv__mobile"
                  //   placeholder="Enter Mobile No."
                  //   value={mobile}
                  //   onChange={(e) => { setMobile(e.target.value) }}
                  // />
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="standard-basic"
                      label="Mobile"
                      variant="standard"
                      value={mobile}
                      onChange={(e) => {
                        setMobile(e.target.value);
                      }}
                    />
                  </Box>
                ) : (
                  <p className="profile__rightBox__info__mobileDiv__mobile__p">
                    {mobile}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="profile__rightBox__bottomDiv">
            <div className="profile__rightBox__bottomDiv__leftBox">
              <h3>Information</h3>

              <div>
                <p>Referred By:</p>
                <p className="profile__rightBox__bottomDiv__leftBox__id">
                  {userInfo?.referredBy !== undefined
                    ? userInfo.referredBy
                    : "-"}
                </p>
              </div>
              <div>
                <p>Parent Id:</p>
                <p className="profile__rightBox__bottomDiv__leftBox__id">
                  {userInfo?.parentReferralCode !== undefined
                    ? userInfo.parentReferralCode
                    : "-"}
                </p>
              </div>

              {/* <div className="profile__rightBox__bottomDiv__leftBox__buttons">
              {update ?
                [<button onClick={() => { updateProfileDetails() }} className="profile__rightBox__bottomDiv__leftBox__buttons__btn">
                  Save
                </button>,
                <button onClick={() => { cancelButtonHandler() }} className="profile__rightBox__bottomDiv__leftBox__buttons__btn">
                  Cancel
                </button>]
                : null}
            </div> */}
            </div>

            <div className="profile__rightBox__bottomDiv__rightBox">
              <div>
                <p className="profile__rightBox__bottomDiv__rightBox__heading">
                  Plan
                </p>
                <p className="profile__rightBox__bottomDiv__rightBox__counts">
                  {userInfo?.joiningDetails?.selectedPlan !== undefined
                    ? userInfo.joiningDetails.selectedPlan
                    : "0"}
                </p>
              </div>

              <div>
                <p className="profile__rightBox__bottomDiv__rightBox__heading">
                  Current Balance
                </p>
                <p className="profile__rightBox__bottomDiv__rightBox__counts balance">
                  {userInfo?.walletAmount !== undefined
                    ? userInfo.walletAmount
                    : "0"}
                </p>
              </div>
            </div>
          </div>

          <div className="profile__rightBox__bottomDiv__leftBox__buttons">
            {update
              ? [
                  <button
                    onClick={() => {
                      updateProfileDetails();
                    }}
                    className="profile__rightBox__bottomDiv__leftBox__buttons__btn"
                  >
                    Save
                  </button>,
                  <button
                    onClick={() => {
                      cancelButtonHandler();
                    }}
                    className="profile__rightBox__bottomDiv__leftBox__buttons__btn"
                  >
                    Cancel
                  </button>,
                ]
              : null}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="w-full px-5 flex-col  mt-10 bg-bgColor dark:bg-darkbgColor pb-10">
      {name && (
        <div className="flex gap-3 items-centel">
          <p className=" text-3xl text-black dark:text-white font-semibold capitalize  ">
            {name}
          </p>
        </div>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <h2>Coming Soon...</h2> */}

      {/* <div className="relative mt-8 h-72 w-full overflow-hidden rounded-xl bg-[url(https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80)] bg-cover	bg-center -z-50 ">
        <div className="absolute inset-0 h-full w-full bg-blue-500/50 -z-50" />
      </div> */}
      <div className="">
        <div className=" flex  flex-wrap justify-between">
          <div className="relative   rounded-lg w-full lg:w-[35%] bg-white dark:bg-darktheme p-4">
            <SettingsIcon
              className="absolute top-2 z-30 right-2 text-black dark:text-white cursor-pointer"
              onClick={() => setUpdate(true)}
            />
            <div
              style={{
                background: `url('https://static.vecteezy.com/system/resources/thumbnails/027/681/856/small/pink-plexus-abstract-digital-connection-moving-dots-and-lines-technology-background-free-video.jpg')`,
                resize: "contain",
                objectFit: "center",
              }}
              className="w-full flex flex-col items-center justify-center b   relative h-32 bg-theme object-contain resize-y rounded-lg"
            >
              <img
                src={
                  userDetails.profileImage !== ""
                    ? BASE_FILE_PATH + userDetails.profileImage
                    : avtar
                }
                className="h-32 w-32 rounded-full absolute -bottom-10"
              />
            </div>

            <p className="text-xl  mt-2 text-black dark:text-white pt-10 text-center capitalize font-bold ">
              {name}
            </p>

            <div className="flex flex-col items-start border-t border-black dark:border-white mt-4">
              <Typography
                variant="h5"
                className="mb-[50px!important] text-gray-300 text-center w-full"
              >
                {userDetails.referralId}
              </Typography>

              <div className="flex gap-3 items-center ">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2"
                    y="2"
                    width="36"
                    height="36"
                    rx="18"
                    fill="#D2D5DC"
                  />
                  <rect
                    x="2"
                    y="2"
                    width="36"
                    height="36"
                    rx="18"
                    stroke="#E5E5E5"
                    stroke-width="4"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 15C10 13.3431 11.3431 12 13 12H27C28.6569 12 30 13.3431 30 15V25C30 26.6569 28.6569 28 27 28H13C11.3431 28 10 26.6569 10 25V15ZM26.3334 14H13.6667L19.4 18.3C19.7556 18.5667 20.2445 18.5667 20.6 18.3L26.3334 14ZM12 15.25V25C12 25.5523 12.4477 26 13 26H27C27.5523 26 28 25.5523 28 25V15.25L21.8 19.9C20.7334 20.7 19.2667 20.7 18.2 19.9L12 15.25Z"
                    fill="#667085"
                  />
                </svg>

                <div className=" my-2">
                  <h5 className="dark:text-gray-300  text- font-medium text-base ">
                    Email :{" "}
                  </h5>
                  <p className="text-darktheme dark:text-white font-semibold">
                    {userInfo?.email !== undefined ? userInfo.email : "-"}
                  </p>
                </div>
              </div>
              <div className="flex gap-3 items-center py-2">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2"
                    y="2"
                    width="36"
                    height="36"
                    rx="18"
                    fill="#D2D5DC"
                  />
                  <rect
                    x="2"
                    y="2"
                    width="36"
                    height="36"
                    rx="18"
                    stroke="#E5E5E5"
                    stroke-width="4"
                  />
                  <g clip-path="url(#clip0_76_121)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M27.9733 26.6595L25.0235 23.7097L23.0774 26.2869L22.3703 26.0078L22.3658 26.006L22.3569 26.0025L22.3268 25.9903C22.3012 25.9799 22.2649 25.965 22.2189 25.9456C22.1268 25.907 21.9956 25.8506 21.8336 25.7776C21.5099 25.6317 21.0603 25.4182 20.551 25.1451C19.5467 24.6065 18.2503 23.8047 17.2414 22.7958C16.2325 21.7869 15.4306 20.4904 14.892 19.486C14.6188 18.9767 14.4053 18.5271 14.2594 18.2034C14.1863 18.0413 14.13 17.9101 14.0913 17.8181C14.0719 17.772 14.057 17.7357 14.0466 17.7102L14.0344 17.68L14.0309 17.6712L14.0294 17.6674L13.75 16.9596L16.3272 15.0134L13.3774 12.0636C12.7925 13.0194 12.04 14.6448 12.0352 16.6657C12.0302 18.7616 12.8282 21.4138 15.7258 24.3114C18.6233 27.209 21.2756 28.0069 23.3713 28.0018C25.3923 27.997 27.0176 27.2444 27.9733 26.6595ZM16.1928 17.621L17.5325 16.6094C18.5016 15.8776 18.6002 14.4579 17.7414 13.5992L14.7053 10.5631C13.9158 9.7735 12.4908 9.73464 11.7872 10.8347C11.0751 11.9482 10.0415 14.0138 10.0352 16.661C10.0289 19.3443 11.0788 22.4929 14.3115 25.7256C17.5443 28.9584 20.6929 30.0083 23.3761 30.0018C26.0233 29.9955 28.0889 28.9618 29.2023 28.2497C30.3023 27.5461 30.2634 26.1211 29.4739 25.3316L26.4377 22.2954C25.579 21.4367 24.1593 21.5352 23.4275 22.5044L22.4158 23.8441C22.1571 23.7229 21.8418 23.5678 21.4962 23.3825C20.5598 22.8804 19.4617 22.1877 18.6556 21.3816C17.8495 20.5754 17.1567 19.4772 16.6545 18.5408C16.4691 18.1952 16.314 17.8798 16.1928 17.621Z"
                      fill="#667085"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_76_121">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(8 8)"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <div>
                  <h5 className="dark:text-gray-300  text- font-medium text-base ">
                    Mobile :{" "}
                  </h5>
                  <p className="text-darktheme dark:text-white font-semibold">
                    {mobile}
                  </p>
                </div>
              </div>
              <div className="flex gap-3 items-center py-3">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2"
                    y="2"
                    width="36"
                    height="36"
                    rx="18"
                    fill="#D2D5DC"
                  />
                  <rect
                    x="2"
                    y="2"
                    width="36"
                    height="36"
                    rx="18"
                    stroke="#E5E5E5"
                    stroke-width="4"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17 11C17 10.4477 16.5523 10 16 10C15.4477 10 15 10.4477 15 11H14C12.3431 11 11 12.3431 11 14V27C11 28.6569 12.3431 30 14 30H26C27.6569 30 29 28.6569 29 27V14C29 12.3431 27.6569 11 26 11H25C25 10.4477 24.5523 10 24 10C23.4477 10 23 10.4477 23 11H17ZM27 15V14C27 13.4477 26.5523 13 26 13H25C25 13.5523 24.5523 14 24 14C23.4477 14 23 13.5523 23 13H17C17 13.5523 16.5523 14 16 14C15.4477 14 15 13.5523 15 13H14C13.4477 13 13 13.4477 13 14V15H27ZM13 17V27C13 27.5523 13.4477 28 14 28H26C26.5523 28 27 27.5523 27 27V17H13Z"
                    fill="#667085"
                  />
                </svg>

                <div>
                  <h5 className="dark:text-gray-300  text- font-medium text-base ">
                    DOB :{" "}
                  </h5>
                  <p className="text-darktheme dark:text-white font-semibold">
                    {getDDMMYYYYByTimestamp(dob.getTime())}
                  </p>
                </div>
              </div>
              <div className="flex gap-3 items-center">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2"
                    y="2"
                    width="36"
                    height="36"
                    rx="18"
                    fill="#D2D5DC"
                  />
                  <rect
                    x="2"
                    y="2"
                    width="36"
                    height="36"
                    rx="18"
                    stroke="#E5E5E5"
                    stroke-width="4"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M24 18C24 20.2091 22.2091 22 20 22C17.7909 22 16 20.2091 16 18C16 15.7909 17.7909 14 20 14C22.2091 14 24 15.7909 24 18ZM22 18C22 19.1046 21.1046 20 20 20C18.8954 20 18 19.1046 18 18C18 16.8954 18.8954 16 20 16C21.1046 16 22 16.8954 22 18Z"
                    fill="#667085"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M28 18C27.9999 24.8707 22.0992 28.791 20.4332 29.7609C20.1612 29.9192 19.8388 29.9192 19.5668 29.7609C17.9008 28.791 12 24.8707 12 18C12 14 15 10 20 10C25 10 28 14 28 18ZM14 18C14 14.9372 16.2648 12 20 12C23.7352 12 26 14.9372 26 18C26 21.2825 24.3677 23.8038 22.5857 25.5858C21.7002 26.4714 20.8093 27.1401 20.1406 27.5859C20.0924 27.618 20.0455 27.6489 20 27.6785C19.9544 27.6489 19.9075 27.618 19.8594 27.5859C19.1907 27.1401 18.2998 26.4714 17.4142 25.5858C15.6322 23.8038 14 21.2825 14 18Z"
                    fill="#667085"
                  />
                </svg>

                <div>
                  <h5 className="dark:text-gray-300  text- font-medium text-base ">
                    Address :{" "}
                  </h5>
                  <p className="text-darktheme dark:text-white font-semibold">
                    {address}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className=" w-full lg:w-[62%]">
            <h3 className="text-[28px] text-start dark:text-white font-medium text-darktheme">
              Information
            </h3>

            <div className="flex justify-between flex-wrap">
              <Card
                title={"Referred By"}
                value={`${
                  userInfo?.referredBy !== undefined ? userInfo.referredBy : "-"
                }`}
              />
              <Card
                title={"Parent Id"}
                value={`${
                  userInfo?.parentReferralCode !== undefined
                    ? userInfo.parentReferralCode
                    : "-"
                }`}
              />
              <Card
                title={"Plan"}
                value={
                  userInfo?.joiningDetails?.selectedPlan !== undefined
                    ? userInfo.joiningDetails.selectedPlan
                    : "0"
                }
              />
              <Card
                title={"Current Balance"}
                value={
                  userInfo?.walletAmount !== undefined
                    ? userInfo.walletAmount
                    : "0"
                }
              />
            </div>
          </div>
        </div>
        <Modal
          open={update}
          onClose={() => setUpdate(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="bg-white dark:bg-darktheme outline-transparent border-none translate rounded-xl p-5 overflow-y-auto shadow-lg w-[90%] min-h-[650px] py-5 lg:w-[500px] overflow-scroll absolute left-[50%] right-[50%] top-[50%] bottom-[50%]">
            <div className="flex flex-col w-full items-center justify-center h-full">
              <div className="w-full max-w-md rounded-lg  p-6">
                <h2 className="text-2xl font-bold dark:text-gray-300 mb-4">
                  Edit Your Information
                </h2>

                <form className="flex flex-col">

                <div class="flex items-start justify-start flex-col w-full">
                    <label
                      for="dropzone-file"
                      style={{
                        background: `url(${
                          BASE_FILE_PATH + userDetails.profileImage
                        })`,
                        backgroundSize: "contain",
                      }}
                      class="flex relative flex-col items-center justify-center  h-20 rounded-full w-20 border border-gray-300   cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                      <div class="flex flex-col items-center justify-center pt-5 pb-6"></div>
                      <input
                        placeholder="Profile Picture"
                        onChange={(e) => {
                          handleFileUploadButton(e);
                        }}
                        accept="image/png, image/gif, image/jpeg"
                        id="dropzone-file"
                        type="file"
                        class="hidden"
                      />

                      <svg
                        className="absolute -bottom-3 right-0"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="28" height="28" rx="8" fill="#DAECFF" />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M21.3047 10.8199C22.281 9.8436 22.281 8.26069 21.3047 7.28438L20.7155 6.69512C19.7391 5.71881 18.1562 5.71881 17.1799 6.69512L7.69097 16.1841C7.34624 16.5288 7.10982 16.9668 7.01082 17.4442L6.34111 20.6735C6.21932 21.2607 6.73906 21.7805 7.32629 21.6587L10.5556 20.989C11.033 20.89 11.471 20.6536 11.8158 20.3089L21.3047 10.8199ZM20.1262 8.46289L19.5369 7.87363C19.2115 7.5482 18.6839 7.5482 18.3584 7.87363L17.4745 8.75755L19.2423 10.5253L20.1262 9.6414C20.4516 9.31596 20.4516 8.78833 20.1262 8.46289ZM18.0638 11.7038L16.296 9.93606L8.86948 17.3626C8.75457 17.4775 8.67577 17.6235 8.64277 17.7826L8.23082 19.769L10.2172 19.3571C10.3763 19.3241 10.5223 19.2453 10.6373 19.1303L18.0638 11.7038Z"
                          fill="#6038CC"
                        />
                      </svg>
                    </label>
                  </div>


                  <label className="mt-6 dark:text-white">Name</label>
                  <input
                    type="text"
                    className="bg-white dark:bg-darktheme border dark:border-white p-2 rounded-md my-3"
                    placeholder="Name"
                    onChange={(e) => setName(e.target.value)}
                  />

                  <label className="dark:text-white">Address</label>
                  <input
                    type="text"
                    className="bg-white dark:bg-darktheme border dark:border-white p-2 rounded-md mt-2"
                    placeholder="Address"
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />

                  <label className="mt-4 dark:text-white">Phone Number</label>

                  <input
                    type="text"
                    className="bg-white dark:bg-darktheme border dark:border-white p-2 rounded-md my-3"
                    placeholder="Phone Number"
                    onChange={(e) => setMobile(e.target.value)}
                  />

                  <label className="dark:text-white">Phone Number</label>
                  <div className="bg-white dark:bg-darktheme border dark:border-white p-2 rounded-md mt-2">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        style={{background:'red'}}
                        sx={{ background: "red" }}
                        className=""
                        value={dob}
                        onChange={(newValue) => {
                          setDob(newValue.$d);
                        }}
                        renderInput={(params) => (
                          <TextField sx={{ border: "red" }} {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

        

                  <div className="flex w-full gap-4 mt-3">
                    <button
                      type="button"
                      className=" w-full bg-[#8153FF] text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-indigo-600 hover:to-blue-600 transition ease-in-out duration-150"
                      onClick={() => {
                        updateProfileDetails();
                      }}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className=" w-full bg-[#8153FF] text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-indigo-600 hover:to-blue-600 transition ease-in-out duration-150"
                      onClick={() => {
                        cancelButtonHandler();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

const Card = ({ title, value, icon, width }) => {
  return (
    <div
      className={`bg-white w-full dark:bg-darktheme text-black dark:text-white ${
        width ? width : "lg:w-[30%]"
      } p-4 py-6 rounded-lg mt-5 shadow-md  flex items-center`}
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="20" fill="#0064C1" />
        <path
          d="M14 22C14 21.4477 14.4477 21 15 21H16C16.5523 21 17 21.4477 17 22C17 22.5523 16.5523 23 16 23H15C14.4477 23 14 22.5523 14 22Z"
          fill="white"
        />
        <path
          d="M24 21C23.4477 21 23 21.4477 23 22C23 22.5523 23.4477 23 24 23H25C25.5523 23 26 22.5523 26 22C26 21.4477 25.5523 21 25 21H24Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.4753 17.9158L12.7586 13.638C13.1393 12.369 14.3073 11.5 15.6321 11.5H24.3679C25.6927 11.5 26.8607 12.369 27.2414 13.638L28.5248 17.9158C29.4078 18.438 30 19.3998 30 20.5V23.5C30 24.8062 29.1652 25.9175 28 26.3293V28C28 28.2761 27.7761 28.5 27.5 28.5H26.5C26.2239 28.5 26 28.2761 26 28V26.5H14V28C14 28.2761 13.7761 28.5 13.5 28.5H12.5C12.2239 28.5 12 28.2761 12 28V26.3293C10.8348 25.9175 10 24.8062 10 23.5V20.5C10 19.3998 10.5922 18.438 11.4753 17.9158ZM15.6321 13.5H24.3679C24.8095 13.5 25.1988 13.7897 25.3257 14.2127L26.3119 17.5H13.6881L14.6743 14.2127C14.8012 13.7897 15.1905 13.5 15.6321 13.5ZM13 19.5C12.4477 19.5 12 19.9477 12 20.5V23.5C12 24.0523 12.4477 24.5 13 24.5H27C27.5523 24.5 28 24.0523 28 23.5V20.5C28 19.9477 27.5523 19.5 27 19.5H13Z"
          fill="white"
        />
      </svg>
      <div className="ml-3">
        <p className="text-gray-600 font-medium dark:text-[#D4DFEB]">{title}</p>
        <p className="text-xl font-bold">{value}</p>
      </div>
    </div>
  );
};
