import React, { useEffect, useState } from "react";
// import Logo from '../assets/Logo.png'
import Logo from "../../assets/Logo.png";
import { HiBars3BottomLeft } from "react-icons/hi2";
import { FaXmark } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import { Popover } from "@mui/material";
import { Link } from "react-scroll";

function Navbar() {
  const [width, setWidth] = useState(window.innerWidth);
  const [toggle, setIsToggle] = useState(true);
  const [dropDown,setDropDown]=useState(false)
  const routeToggle = () => {
    // console.log("shshs");
    if (width < 1024) {
      setIsToggle(true);
    } else {
      setIsToggle(!toggle);
      // console.log("pom"
    }
  };

  useEffect(() => {
    const handleResize = () => {
      // Check the viewport width and update the state accordingly
      setIsToggle(window.innerWidth >= 1024 ? true : false);
    };

    // Initial check on mount
    handleResize();

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); //




  return (
    <div>
      <nav class="fixed w-screen z-50 ">
        <div class=" container relative flex flex-wrap items-center justify-between mx-auto ">
          <a
            href="https://flowbite.com/"
            class="hidden lg:flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img src={Logo} class="h-10" alt="Flowbite Logo" />
          </a>
          <button
            onClick={() => setIsToggle(!toggle)}
            // data-collapse-toggle="navbar-default"
            type="button"
            class="absolute top-6 items-center right-3  lg:hidden  text-4xl"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            {!toggle ? (
              <HiBars3BottomLeft className="text-white" />
            ) : (
              <FaXmark className="text-white" />
            )}
          </button>

          <img
            src={Logo}
            className="ml-4 absolute h-8 w-auto block lg:hidden top-7 "
          />
          {true && (
            <div
              class={`${
                toggle ? "block" : "hidden"
              }  flex  duration-300 bg-blueC lg:bg-transparent w-screen lg:w-full md:block md:w-auto`}
            >
              <ul class=" flex  flex-col p-4 md:p-0 mt-10 lg:mt-0  w-full  lg:h-auto rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 bg-transparent text-lg font-">
                <li className="w-full ">
                  <Link
                      disabled={toggle ? false : true}
                      onClick={routeToggle}
                      to={'home'}
                      spy={true}
                      activeClass="active"
                      smooth={true}
                      offset={-100}
                      duration={800}
                  >
                  <p
                    href="#"
                    class="
                  cursor-pointer transition-all      - border-green-400  hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[2px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px] hover:shadow-xl hover:shadow-green-300 shadow-green-300 active:shadow-none

                  block  px-3 w-full text-white mt-3 lg:my-0"
                    aria-current="page"
                  >
                    Home
                  </p>
                  </Link>
                </li>
                <li>
                <Link
                      disabled={toggle ? false : true}
                      onClick={routeToggle}
                      to={'about'}
                      spy={true}
                      activeClass="active"
                      smooth={true}
                      offset={0}
                      duration={800}
                  >
                  <p
                    href="#"
                    class="
                  cursor-pointer transition-all       hover:border-green-400  hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[2px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px] hover:shadow-xl hover:shadow-green-300 shadow-green-300 active:shadow-none

                  block  px-3 w-full text-white mt-3 lg:my-0"
                    aria-current="page"
                  >
                    About
                  </p>
                  </Link>
                </li>

                <li className="mt-3 lg:mt-0">
                  <button
                    onClick={()=>setDropDown(!dropDown)}
                    class="text-white  flex items-center relative"
                    type="button"
                  >
                    Services
                    <svg
                      class="w-2.5 h-2.5 ms-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </button>

                 {dropDown&& <div
                    class="z-10 mt-2 absolute gbbb divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
                  >
                    <ul
                      class="py-2 text-sm text-gray-700 dark:text-gray-200"
                    >
                      <li>
                      <Link
                       data-dropdown-trigger="hover"
                      disabled={toggle ? false : true}
                      onClick={()=>{
                        routeToggle()
                        setDropDown(false)
                      }}
                      to={'zepgame'}
                      spy={true}
                      activeClass="active"
                      smooth={true}
                      offset={-100}
                      duration={800}
                  >
                        <p
                          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          ZepGame
                        </p>
                        </Link>
                      </li>
                      <li>
                      <Link
                      disabled={toggle ? false : true}
                      onClick={()=>{
                        routeToggle()
                        setDropDown(false)
                      }}
                      to={'zepcoin'}
                      spy={true}
                      activeClass="active"
                      smooth={true}
                      offset={-100}
                      duration={800}
                  >
                        <p
                          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          ZepCoin
                        </p>
                        </Link>
                      </li>
                      <li>
                      <Link
                      disabled={toggle ? false : true}
                      onClick={()=>{
                        routeToggle()
                        setDropDown(false)
                      }}
                      to={'zepexchange'}
                      spy={true}
                      activeClass="active"
                      smooth={true}
                      offset={-100}
                      duration={800}
                  >
                        <p
                          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          ZepExchange
                        </p>
                        </Link>
                      </li>
                      <li>
                      <Link
                      disabled={toggle ? false : true}
                      onClick={()=>{
                        routeToggle()
                        setDropDown(false)
                      }}
                      to={'zepexswap'}
                      spy={true}
                      activeClass="active"
                      smooth={true}
                      offset={-100}
                      duration={800}
                  >
                        <p
                          class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          ZepSwap
                        </p>
                        </Link>
                      </li>
                  
                    </ul>
                  </div>}
                </li>


                <div class="  flex lg:hidden  mt-4 right-0 md:order-2 space-x-3 gap-x-4 md:space-x-0 rtl:space-x-reverse">
            <NavLink to={"/login"}>
              <button
                type="button"
                class="bg-gradient-to-tr from-green-500 via-transparent to-theme text-white p-2 px-8 rounded font-semibold
              
              cursor-pointer transition-all     py-2 - border-green-400  hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[2px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px] hover:shadow-xl hover:shadow-green-300 shadow-green-300 active:shadow-none
              "
              >
                Login
              </button>
            </NavLink>

            <NavLink to={"/register"}>
              <button
                type="button"
                class="
              cursor-pointer transition-all     py-2 - border-green-400  hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[2px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px] hover:shadow-xl hover:shadow-green-300 shadow-green-300 active:shadow-none

              bg-gradient-to-tr from-green-500 via-transparent to-theme text-white p-2 px-8 rounded font-semibold"
              >
                Register
              </button>
            </NavLink>
          </div>
              </ul>
            </div>
          )}

          <div class="  hidden lg:flex  absolute right-0 md:order-2 space-x-3 gap-x-4 md:space-x-0 rtl:space-x-reverse">
            <NavLink to={"/login"}>
              <button
                type="button"
                class="bg-gradient-to-tr from-green-500 via-transparent to-theme text-white p-2 px-8 rounded font-semibold
              
              cursor-pointer transition-all     py-2 - border-green-400  hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[2px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px] hover:shadow-xl hover:shadow-green-300 shadow-green-300 active:shadow-none
              "
              >
                Login
              </button>
            </NavLink>

            <NavLink to={"/register"}>
              <button
                type="button"
                class="
              cursor-pointer transition-all     py-2 - border-green-400  hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[2px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px] hover:shadow-xl hover:shadow-green-300 shadow-green-300 active:shadow-none

              bg-gradient-to-tr from-green-500 via-transparent to-theme text-white p-2 px-8 rounded font-semibold"
              >
                Register
              </button>
            </NavLink>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
