import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { getBusinessHistoryService } from '../../services/userServices';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from 'react';
import './tables.css'
const columns = [
    {
        field: 'id',
        headerName: 'SL',
        width: 90
    },
    {
        field: 'ref',
        headerName: 'Member Id',
        width: 150,
    },
    {
        field: 'plan',
        headerName: 'Plan Amount',
        width: 150,
    },
    {
        field: 'commision',
        headerName: 'Commision',
        width: 110,
    },
    {
        field: 'level',
        headerName: 'Level',
        width: 110,
    },
    {
        field: 'commisionType',
        headerName: 'Commission Type',
        width: 150,
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 200
    },
];

export default function BusinessHistory() {

    const getTokenData = useSelector(state => state.tokenReducer)
    const [isLoading, setIsLoading] = useState(false);

    const [incomeDetails, setIncomeDetails] = React.useState([])

    const getCommisionTypeByValue = (type) => {
        switch (type) {
            case 1: return 'For New Joining'
            case 2: return 'For AutoTopup'
            case 3: return 'For Manual Topup'
            case 4: return 'For ZepX Referral'
            case 5: return 'For 3% Commission'
            case 6: return 'For 1% Commission'
            default: return ''
        }
    }

    const getIncomeDetails = async () => {
        try {
            setIsLoading(true)
            let res = await getBusinessHistoryService(getTokenData.token)
            let temp = []
            res.data.result.map((d, i) => {
                temp.push({
                    id: (i + 1).toString(),
                    ref: d.newMemberReferralCode ? (d.newMemberReferralCode).toString() : '-',
                    plan: (d.totalAmount).toString(),
                    commision: (d.commisionAmount.toString()),
                    level: d.commisionLevel,
                    commisionType: d?.commisionType ? getCommisionTypeByValue(d.commisionType) : getCommisionTypeByValue(1),
                    date: new Date(d.timeStamp)
                })
                return d
            })
            setIncomeDetails([...temp])
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getIncomeDetails()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className='w-full h-[100dvh!important]  flex  px-2 lg:px-5 pt-6 lg:pt-10'>
             <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

            {incomeDetails.length > 0 && <Box className='w-full h-[100%!important]'>
                <DataGrid sx={{ backgroundColor: 'white' }}
                    rows={incomeDetails}
                    columns={columns}
                    pageSize={15}
                    rowsPerPageOptions={[15]}
                    className='bg-white dark:bg-darktheme border-0 text-darktheme dark:text-white w-full h-[90%!important] lg:h-[80%!important]'
                />
            </Box>}
        </div>
    );
}
