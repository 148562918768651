import React, { useState } from "react";
import keyfeaturesRightEngishImg from "../../assets/images/keyfeaturesRightEngishImg.png";
import thumbnil from '../../assets/thumbnil.png'
import { NavLink } from "react-router-dom";
function KeyFeature() {
  const [play, setPlay] = useState(false);
  return (
    <div>
      <p data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"  className="gradientText  font-semibold text-center text-3xl lg:text-6xl my-10">
        Key Features{" "}
      </p>
      <div className="mx-auto">
        <div
          className={` rounded-xl flex items-center justify-center mt-10  p-1 rounded-lg`}
        >
          <div data-aos="fade-in"
       className="w-[70vw] relative">
            {!play && (
              <img
                onClick={() => setPlay(!play)}
                className={`${
                  !play ? "absolute" : "hidden"
                } z-50 h-full w-full object-fill rounded-xl`}
                src={thumbnil}
              />
            )}

            {
              <video className=" w-[100%] rounded-lg" controls autoPlay={play}>
                <source
                  src={"https://zepx.io/zepxasset/files/assets/newvedio.mp4"}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            }
          </div>
        </div>
      </div>

      <div className="mt-10 flex-wrap justify-around py-10 mx-auto  flex items-center w-[80%]">
        <NavLink to={"login"}>
          <div
            type="button"
            class="bg-gradient-to-r from-green-500 via-transparent to-blue-500 text-white p-2 px-16 rounded font-semibold
                text-3xl  text-center
              cursor-pointer transition-all w-[300px]     py-4 - border-green-400  hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[2px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px] hover:shadow-xl hover:shadow-green-300 shadow-green-300 active:shadow-none
              "
          >
            Login
          </div>
        </NavLink>

        <a
          href="https://zepx.io/zepxasset/files/assets/zepx_profile.pdf"
          target="_blank"
          type="button"
          class="bg-gradient-to-r from-green-500 via-transparent to-blue-500 text-white px-4 py-4 rounded font-semibold
                text-3xl mt-5 lg:mt-0 lg:ml-3 w-[300px] text-center
              cursor-pointer transition-all      - border-green-400  hover:brightness-110 hover:-translate-y-[1px] hover:border-b-[2px] active:border-b-[2px] active:brightness-90 active:translate-y-[2px] hover:shadow-xl hover:shadow-green-300 shadow-green-300 active:shadow-none
              "
        >
          Business Plan
        </a>
      </div>
    </div>
  );
}

export default KeyFeature;
