import React from 'react'
import spiderman from '../../assets/spiderman.png'
import joinnow from '../../assets/joinnow.png'
import { ZEPX_IN_ONE_DOLLAR } from '../../Constants';
import { getDDMMYYYYByTimestamp } from '../../helpers/DateAndTimeFunctions';


function CurrentValue() {

    const pricezepx = (1 / ZEPX_IN_ONE_DOLLAR).toFixed(6);

  return (
    <div 
    style={{ background: `url(${spiderman})`, backgroundSize: "cover" }}

    className='w-screen h-[80vh] justify-center flex items-center'>
        <div data-aos="fade-up"   className='w-[90vw] currentValue p-10 mx-auto h-[80%] flex flex-wrap '>
            <div className=' text-center w-full flex flex-col justify-around lg:w-[70%]'>
                <p className=' text-3xl lg:text-5xl text-center text-white font-semibold'>Current Value of Zepx</p>

                <div className='text-lg my-2 lg:text-3xl items-center justify-center flex font-medium text-white'>
                    <p>Today's Price  :  </p>
                    <p className='mx-1'>{getDDMMYYYYByTimestamp(new Date().getTime())}</p>
                </div>

                <p className=' text-lg lg:text-4xl font-medium text-white '>There has been a Growth in zepx today.</p>

                <span className="text-white  text-2xl lg:text-4xl font-medium text-white">{pricezepx&&<>Zepx - {ZEPX_IN_ONE_DOLLAR}</>}</span>
                <span className=" text-2xl lg:text-4xl font-medium text-white">{pricezepx&&<>Zepx - {pricezepx}</>}</span>


            </div>
            <div className='w-full lg:w-[30%] items-center justify-center'>
                <img className='w-[80%]' src={joinnow}/>
            </div>
        </div>
    </div>
  )
}

export default CurrentValue