import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { getAllTeamMemberService } from '../../services/userServices';
import { useSelector } from 'react-redux';
import './ReferrList.css'
import { useEffect } from 'react';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from 'react';
import './tables.css'

const columns = [
  {
    field: 'sl',
    headerName: 'SL',
    width: 90
  },
  {
    field: 'id',
    headerName: 'Member Id',
    width: 150,
  },
  {
    field: 'referredBy',
    headerName: 'Referred By'
  },
  {
    field: 'parentId',
    headerName: 'Parent Id'
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 110,
  },
  {
    field: 'plan',
    headerName: 'Plan',
    width: 110,
  },
  {
    field: 'date',
    headerName: 'Joining Date',
    width: 200
  },
];

export default function ReferrList() {

  const getTokenData = useSelector(state => state.tokenReducer)
  const userDetails = useSelector(state => state.userReducer)
  const [isLoading, setIsLoading] = useState(false);

  const [incomeDetails, setIncomeDetails] = React.useState([])

  const getIncomeDetails = async () => {
    try {
      setIsLoading(true)
      let res = await getAllTeamMemberService(getTokenData.token)
      let temp = []
      res.data.result.map((d, i) => {
        if (d.referredBy === userDetails.referralId) {
          temp.push({
            sl: (i + 1).toString(),
            id: (d.ownReferralCode).toString(),
            referredBy: d.referredBy,
            parentId: d.parentReferralCode,
            email: d.email,
            plan: d.joiningDetails.selectedPlan.toString(),
            date: new Date(d.joiningDetails.dateTimestamp)
          })
        }
        return d
      })
      setIncomeDetails([...temp])
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getIncomeDetails()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className='w-full h-[100dvh!important]  flex  px-5 pt-10'>
       <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {incomeDetails.length > 0 && <Box className='w-full h-[100%!important]'>
        <DataGrid 
          rows={incomeDetails}
          columns={columns}
          pageSize={8}
          rowsPerPageOptions={[8]}
          className='bg-white dark:bg-darktheme border-0 text-darktheme dark:text-white w-full h-[80%!important]'
          />
      </Box>}
    </div>
  );
}
