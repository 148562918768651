let statusIntitialState = {
    isShow: false
}

export const globalNotificationReducer = (state = statusIntitialState, action) => {
    switch (action.type) {
        case 'SET_ISSHOW':
            return {
                ...state,
                isShow: action.payload,
            };
       

        default:
            return state;
    }
}