import axios from "axios";
import { SERVER_URL } from "../GlobalVariable.js";

const getAxiosHeader = (token) => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token
        }
    }
}


export const getUserDetailService = async (token) => {
    let url = `${SERVER_URL}/api/user/getUserDetails`
    return await axios.get(url, getAxiosHeader(token))
}

export const getBusinessHistoryService = async (token) => {
    let url = `${SERVER_URL}/api/user/getBusinessHistory`
    return await axios.get(url, getAxiosHeader(token))
}

export const getTransactionHistoryService = async (token) => {
    let url = `${SERVER_URL}/api/user/getTransactionHistory`
    return await axios.get(url, getAxiosHeader(token))
}

export const getAllTeamMemberService = async (token) => {
    let url = `${SERVER_URL}/api/user/getAllTeamMember`
    return await axios.get(url, getAxiosHeader(token))
}

export const getDirectTeamMemberService = async (token) => {
    let url = `${SERVER_URL}/api/user/getDirectTeamMember`
    return await axios.get(url, getAxiosHeader(token))
}
export const getAllTeamMemberWithBussinessService = async (data ,token) => {
    let url = `${SERVER_URL}/api/user/getteamwithbussiness`
    return await axios.post(url, data, getAxiosHeader(token))
}
export const getDirectIncomeService = async (token) => {
    let url = `${SERVER_URL}/api/user/getDirectIncome`
    return await axios.get(url, getAxiosHeader(token))
}

export const depositService = async (data, token) => {
    let url = `${SERVER_URL}/api/user/deposit`
    return await axios.post(url, data, getAxiosHeader(token))
}

export const withdrawService = async (data, token) => {
    let url = `${SERVER_URL}/api/user/withdraw`
    return await axios.post(url, data, getAxiosHeader(token))
}

export const withdrawZepxService = async (data, token) => {
    let url = `${SERVER_URL}/api/user/withdrawzepx`
    return await axios.post(url, data, getAxiosHeader(token))
}

export const generateOtpForWithdrawService = async (data, token) => {
    let url = `${SERVER_URL}/api/user/generateOtpForWithdraw`
    return await axios.post(url, data, getAxiosHeader(token))
}

export const generateOtpForZepxWithdrawService = async (data, token) => {
    let url = `${SERVER_URL}/api/user/generateOtpForZepxWithdraw`
    return await axios.post(url, data, getAxiosHeader(token))
}

export const generateOtpForp2ptransferService = async (data, token) => {
    let url = `${SERVER_URL}/api/user/generateOtpForp2ptransfer`
    return await axios.post(url, data, getAxiosHeader(token))
}

export const generateOtpForp2pactiveService = async (data, token) => {
    let url = `${SERVER_URL}/api/user/generateOtpForp2pactive`
    return await axios.post(url, data, getAxiosHeader(token))
}

export const updateAutoTopEnabledService = async (data, token) => {
    let url = `${SERVER_URL}/api/user/updateAutoTop`
    return await axios.put(url, data, getAxiosHeader(token))
}

export const manualTopupService = async (data, token) => {
    let url = `${SERVER_URL}/api/user/manualTopup`
    return await axios.post(url, data, getAxiosHeader(token))
}

export const generateOtpForPasscodeResetService = async (data, token) => {
    let url = `${SERVER_URL}/api/user/generateOtpForPasscodeReset`
    return await axios.post(url, data, getAxiosHeader(token))
}

export const resetPasscodeService = async (data, token) => {
    let url = `${SERVER_URL}/api/user/resetPasscode`
    return await axios.put(url, data, getAxiosHeader(token))
}

export const purchaseZepXService = async (data, token) => {
    let url = `${SERVER_URL}/api/zepx/purchaseZepx`
    return await axios.post(url, data, getAxiosHeader(token))
}

export const purchaseZepXInStakingPlanService = async (data, token) => {
    let url = `${SERVER_URL}/api/zepx/purchaseZepxInStakingPlan`
    return await axios.post(url, data, getAxiosHeader(token))
}

export const checkPurchaseStatusService = async (data, token) => {
    let url = `${SERVER_URL}/api/zepx/checkPurchaseStatus`
    return await axios.post(url, data, getAxiosHeader(token))
}

export const uploadProfileImageService = async (profileImage, imageToRemove) => {
    let url = `${SERVER_URL}/zepxasset/uploadprofileimage`
    let formData = new FormData()
    formData.append('image', profileImage)
    if (imageToRemove !== undefined && imageToRemove !== '') {
        formData.append('removeImage', imageToRemove)
    }
    return await axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

export const updateProfileImageService = async (data, token) => {
    let url = `${SERVER_URL}/api/user/updateProfileImage`
    return await axios.put(url, data, getAxiosHeader(token))
}

export const updateProfileDetailsService = async (data, token) => {
    let url = `${SERVER_URL}/api/user/updateProfileDetails`
    return await axios.put(url, data, getAxiosHeader(token))
}

export const getMemberTreeService = async (token) => {
    let url = `${SERVER_URL}/api/user/getMemberTree`
    return await axios.get(url, getAxiosHeader(token))
}

export const purchaseCouponCodeService = async (data, token) => {
    let url = `${SERVER_URL}/api/coupon/purchasecouponcode`
    return await axios.post(url,data, getAxiosHeader(token))
}

// export const getTeamAndBussinessServices = async (data) => {
//     console.log(data);
//     let url = `${SERVER_URL}/api/user/getTeamAndBussiness`
//     return await axios.post(url,data)
// }
export const getTeamAndBussinessServices = async (data) => {
    // console.log(data);
    let url = `${SERVER_URL}/api/user/getTeamAndBussinessc`
    return await axios.post(url,data)
}



// export const getTeamAndBussinessServices = async (data) => {
//     console.log(data);
//     let url = `${SERVER_URL}/api/user/getTeamAndBussiness`;

//     try {
//         const response = await axios.post(url, data, {
//             timeout: 300000, // 5 minutes in milliseconds
//             headers: {
//                 'Content-Type': 'application/json',
//                 'x-auth-token': '', // Add your token here
//             },
//         });

//         return response.data;
//     } catch (error) {
//         if (axios.isCancel(error)) {
//             console.log('Request canceled:', error.message);
//         } else {
//             console.error('Error fetching data:', error);
//             throw error;
//         }
//     }
// };


export const getAllCampaignListServices = async (token,data) => {
    let url = `${SERVER_URL}/api/user/getAllCampaign`
    return await axios.get(url,data,getAxiosHeader(token))
}


export const getOtherUserDetail = async (data,token) => {
    let url = `${SERVER_URL}/api/user/getOtherUserdetail`
    return await axios.post(url,data,getAxiosHeader(token))
}
export const p2ptransferservice = async (data,token) => {
    let url = `${SERVER_URL}/api/user/p2ptransfer`
    return await axios.post(url,data,getAxiosHeader(token))
}
export const p2puseractivateservice = async (data,token) => {
    let url = `${SERVER_URL}/api/user/p2pactivate`
    return await axios.post(url,data,getAxiosHeader(token))
}