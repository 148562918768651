import React, { useEffect, useState } from "react";
import {
  getAllCampaignListServices,
  getTeamAndBussinessServices,
} from "../services/userServices";
import Countdown from "react-countdown";
import "./UserReward.css";
import Lottie from "lottie-react";
import Animation from "../assets/Animation.json";
import campaignEndImg from "../assets/ended.png";
import achheved from "../assets/achheved.png";
import sucess from "../assets/sucess.gif";




function UserReward() {
  const [campaignList, setCampaignList] = useState([]);
  const [userData, setUserData] = useState({});
  const [campaginStart, setCampaignStart] = useState(null);
  const [campaginTeamDetails, setCampaignTeamDetails] = useState({});
  const [showModal,setShowModal]=useState(false)
  const [load, setLoad] = useState(false);
  const getUserDetails = async () => {
    try {
      const response = localStorage.getItem("userDetails");
      setUserData(JSON.parse(response));
      allCampignList();
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(userData.ownReferralCode, "userDetails");
  const teamAndBussinessList = async (data) => {
    let user = localStorage.getItem("userDetails");
    let id = JSON.parse(user);
    let compareDate = 1704047400000;
    let startDate;
    if (compareDate <= data?.joiningDetails?.dateTimestamp) {
      startDate = data?.joiningDetails?.dateTimestamp;
    } else {
      startDate = compareDate;
    }
    setCampaignStart(startDate);
    let payload = {
      userId: id?.ownReferralCode,
      startDate: startDate,
      endData: Date.now(),
    };

    // let payload = {
    //     userId: id?.ownReferralCode,
    //     startDate: 1600014607000,
    //     endData: 1701414607120,
    //   };

    // console.log(JSON.parse(user));

    try {
      setLoad(true);
      let response = await getTeamAndBussinessServices(payload);
      setCampaignTeamDetails(response.data.result[0]);
      setLoad(false);
      // console.log(response);
    } catch (error) {
      setLoad(false);
      console.log(error);
    }
  };

  const allCampignList = async () => {
    try {
      let response = await getAllCampaignListServices();
      setCampaignList(response.data.result);
      teamAndBussinessList();
      // console.log(response.data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const timerData = (item) => {
    let compareDate = 1704047400000;
    let startDate;
    if (compareDate <= userData?.joiningDetails?.dateTimestamp) {
      startDate = userData?.joiningDetails?.dateTimestamp;
    } else {
      startDate = compareDate;
    }
    let oneDay = 24 * 60 * 60 * 1000;
    // console.log(oneDay * 30 + compareDate);
    let ddd = item?.campaginTimeline * oneDay;
    let endDate = startDate + ddd;
    // console.log(endDate);
    return Number(endDate);
  };

  // console.log(campaignList, "-0-0-0-");
  useEffect(() => {
    allCampignList();
    // teamAndBussinessList();
    getUserDetails();
  }, []);

  return (
    <div className=" relative  bg-bgColor dark:bg-darkbgColor pb-10 w-full  border px-8 flex  flex-wrap justify-between">
      {campaignList !== undefined &&
        campaignList.map((ele, ind) => {
          if (ele?.typeOfCampagin == 1) {
            return (
              <CampaignCard
                item={ele}
                key={ind}
                timerData={timerData}
                campaginTeamDetails={campaginTeamDetails}
                load={load}
                Animation={Animation}
                setShowModal={setShowModal}
              />
            );
          }
        })}
     {showModal && <ClaimModal setShowModal={setShowModal}  />}
    </div>
  );
}

export default UserReward;

const CampaignCard = ({
  item,
  timerData,
  campaginTeamDetails,
  load,
  Animation,
  setShowModal
}) => {
  const [campaignEnd, setCampignEnd] = useState(false);
  const [campaginAchive, setCampaignAchive] = useState(false);
  const onComplete = () => {
    setCampignEnd(true);
    console.log("hello");
  };

  // const calculateTotalAmount = (ele) => {
  //   // console.log("fhll",ele);

  //   // let total = Object.values(ele?.totalBusiness).reduce((a, b) => a + b, 0);
  //   // return total; // Add this line to return the total
  // };
  // calculateTotalAmount()

  function getRandomGradient() {
    // Generate random RGB values for two colors
    const color1 = getRandomColor();
    const color2 = getRandomColor();

    // Create a gradient string using the generated colors
    const gradient = `linear-gradient(to right, ${color1}, ${color2})`;

    return gradient;
  }

  function getRandomColor() {
    // Generate random RGB values
    const r = Math.floor(Math.random() * 200);
    const g = Math.floor(Math.random() * 200);
    const b = Math.floor(Math.random() * 200);

    // Create a CSS color string
    const color = `rgb(${r}, ${g}, ${b})`;

    return color;
  }

  const achiveTarget = (target, index, totalAmount) => {
    if (index == 0) {
      let amount = (target * 50) / 100;
      if (totalAmount >= amount) {
        return "green";
      }
    }
    if (index == 1) {
      let amount = (target * 30) / 100;
      if (totalAmount >= amount) {
        return "green";
      }
    }

    if (index == 2) {
      let amount = (target * 20) / 100;
      if (totalAmount >= amount) {
        return "green";
      }
    }
  };

  const achieveTarget2 = (target, index, totalAmount) => {
   console.log({target},{index},{totalAmount});
   let a = false;
    let b = false;
    let c = false;
    if (index == 0) {
      let amount = (target * 50) / 100;
      if (totalAmount >= amount) {
         a=true;
      }
    }
    if (index == 1) {
      let amount = (target * 30) / 100;
      if (totalAmount >= amount) {
         b=true;
      }
    }

    if (index == 2) {
      let amount = (target * 20) / 100;
      if (totalAmount >= amount) {
         c=true;
      }
    }

    if(a&&b&&c) {
      return true
    }
    else{
      return false
    }

  };

  const capaignAchive = (target, index, totalAmount) => {
    console.log(target, index, totalAmount);
    let status = false;
    let status2 = false;
    let status3 = false;
    if (index == 0) {
      let amount = (target * 50) / 100;
      if (totalAmount >= amount) {
        status = true;
      }
    }
    if (index == 1) {
      let amount = (target * 30) / 100;
      if (totalAmount >= amount) {
        status2 = true;
      }
    }

    if (index == 2) {
      let amount = (target * 20) / 100;
      if (totalAmount >= amount) {
        status3 = true;
      }
    }

    console.log({ status }, { status2 }, { status3 });

    return status;
  };
  return (
    <div
      style={{ background: getRandomGradient() }}
      className="w-full lg:w-[48%]  mt-6 overflow-scroll relative    text-white font-serif  rounded-xl shadow-lg pt-4 px-6 pb-8"
    >
      {campaignEnd && item?.campaginName !== "Crown Diamond" && (
        <div className="h-full w-full bg-transparent p-5  backdrop-blur-lg absolute  right-0 left-0 top-0">
          <img className="h-32 w-32" src={campaignEndImg} />
        </div>
      )}
      <div
        className="mr-10 text-[45px] font-bold text-black text-shadow border rounded-xl text-center border-gray-300 shadow-xl bg-gradient-to-r from-amber-300 to-yellow-700"
        style={{ width: "100%" }}
      >
        {item?.campaginName}
        <div className="mr-8 flex justify-center items-center text-2xl font-bold text-shadow shadow-xl">
          {item?.campaginId !== 11 && (
            <div className="text-[25px] text-shadow text-white w-[25%]">
              {" "}
              <Countdown
                onComplete={onComplete}
                date={new Date(timerData(item))}
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between text-xl">
        <div className="sm:text-[18px] md:text-[25px] text-white text-shadow item-center">
          <span className="text-black text-shadow-red">Reward :</span> $
          {item?.reward}
        </div>
        <div className="sm:text-[18px] md:text-[25px]t text-white text-shadow item-center">
          <span className="text-black text-shadow-red">Target :</span> $
          {item?.target}
        </div>
      </div>

      {load ? (
        <>
          {" "}
          <p className="mt-5 text-3xl text-shadow text-white text-center">
            {" "}
            team size is larger...wait its loading....
          </p>
          <div className="flex justify-around">
            {[1, 1, 1, 1].map((ele, ind) => {
              return (
                <div key={ind} className="h-28 w-28">
                  <Lottie animationData={Animation} loop={true} />
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-4 overflow-auto justify-between mt-4 gap-2">
          {Object.keys(campaginTeamDetails).length > 0 &&
            Object?.keys(campaginTeamDetails?.downlineBusinessDetails).map(
              (ele, ind) => {
                // console.log(ele);
                const alphabetLetter = String.fromCharCode(65 + ind);
                return (
                  <div key={ind} className="flex flex-col items-center">
                    <div
                      style={{
                        background: achiveTarget(
                          item?.target,
                          ind,
                          campaginTeamDetails?.downlineBusinessDetails[ele]
                            ?.totalBusiness
                        ),
                      }}
                      className="!h-28 !w-28 rounded-full bg-gradient-to-r from-slate-900 to-slate-700 text-white items-center justify-center flex mt-4 mx-3 flex-col shadow-xl"
                    >
                      <p className="text-2xl text-shadow">{alphabetLetter}</p>
                      <p className="mt-3 text-shadow">
                        {
                          campaginTeamDetails?.downlineBusinessDetails[ele]
                            ?.referralCode
                        }
                      </p>
                    </div>

                    <p className="mt-5 text-3xl text-shadow text-white text-center">
                      <p className="text-xs">Bussiness</p>
                      {
                        campaginTeamDetails?.downlineBusinessDetails[ele]
                          ?.totalBusiness
                      }
                    </p>

                    <p className="mt-5 text-3xl text-shadow text-white text-center">
                      <p className="text-xs">Team</p>
                      {
                        campaginTeamDetails?.downlineBusinessDetails[ele]
                          ?.teamSize
                      }
                    </p>

                    {achieveTarget2(
                      item?.target,
                      ind,
                      campaginTeamDetails?.downlineBusinessDetails[ele]
                        ?.totalBusiness
                    ) &&
                      !campaignEnd && (
                        <div className="h-20 w-20">
                          <div className="h-full w-full bg-black/40 absolute  right-0 left-0 top-0">
                            <img className="h-32 w-32" src={achheved} />

                            <button
                            onClick={()=>setShowModal(true)}
                              style={{ background: getRandomGradient() }}
                              className="w-[200px] h-14  mt-[55%] mx-auto ml-40 rounded-lg text-white text-2xl font-semibold"
                            >
                              Claim
                            </button>
                          </div>
                        </div>
                      )}
                  </div>
                );
              }
            )}
        </div>
      )}
    </div>
  );
};

const ClaimModal = ({setShowModal}) => {
  return (
    <div onClick={()=>setShowModal(false)}  className="w-screen z-50 h-screen flex justify-center items-center fixed top-0 bg-black/60 left-0">
      <div className="h-72 flex-col rounded-xl w-[80vw] flex justify-center items-center lg:w-[500px] bg-white dark:bg-darktheme">

        <img src={sucess} className="h-48 w-48"/>
      <p className="capitalize text-2xl font-bold">Your Request has been submited</p>
      </div>
    </div>
  );
};
