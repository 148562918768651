import React from 'react'
import Rectangle from '../../assets/Rectangle.png'


function UtilizationandAllocation() {
  return (
    <div className='  w-[90%] mx-auto'>
      <p className="gradientText  font-semibold text-center text-3xl lg:text-6xl my-10">
        Utilisation of Zepx
        </p>

        <div className='flex flex-wrap w-full justify-between'>

            <div data-aos="flip-down"  className='w-full lg:w-2/4 flex items-center justify-center'>
                <img className='w-[80%]' src={Rectangle}/>
            </div>


            <div data-aos="flip-up"  className='w-full lg:w-2/4 flex items-center'>
                <div style={{textShadow:'2px 2px #ff0000;'}}  className='w-full lg:w-[80%] text-lg text-white' >
                ZEPX is a BEP-20 token designed for use within blockchain-based gaming ecosystems and blockchain-related activities. Gamers can utilize ZEPX for in-game purchases, items, and enhancements, creating an immersive gaming experience. Additionally, ZEPX can facilitate transactions and rewards within decentralized applications, fostering engagement in blockchain activities such as decentralized finance (DeFi) platforms, NFT marketplaces, and more. Its versatility and compatibility with the Binance Smart Chain (BSC) make ZEPX a valuable digital asset for enhancing both gaming and broader blockchain interactions.
                </div>
            </div>

        </div>
    </div>
  )
}

export default UtilizationandAllocation