import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { getTransactionHistoryService } from "../../services/userServices";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";

const columns = [
  {
    field: "id",
    headerName: "Sr. No.",
    width: 90,
  },
  {
    field: "narration",
    headerName: "Narration",
    width: 250,
  },
  {
    field: "currency",
    headerName: "Currency",
    width: 170,
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 150,
  },
  {
    field: "transactionHash",
    headerName: "Transaction Hash",
    width: 250,
  },
  {
    field: "date",
    headerName: "Date",
    width: 250,
  },
];

export default function TransactionHistory() {
  const getTokenData = useSelector((state) => state.tokenReducer);
  const [isLoading, setIsLoading] = useState(false);

  const [incomeDetails, setIncomeDetails] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState(null);
  const getIncomeDetails = async () => {
    try {
      setIsLoading(true);
      let res = await getTransactionHistoryService(getTokenData.token);
      let temp = [];
      let totalAmount = 0;
      res.data.result.map((d, i) => {
        temp.push({
          id: (i + 1).toString(),
          narration: d.narration,
          currency: d.currency,
          amount: d.amount,
          transactionHash: d.transactionHash,
          date: new Date(d.timeStamp),
        });
        totalAmount += d.amount;
        return d;
      });
      temp.push({
        id: "total",
        narration: "Total",
        currency: "",
        amount: totalAmount,
        transactionHash: "",
        date: "",
      });
      setIncomeDetails([...temp]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getIncomeDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 

  console.log(totalAmount);
  return (
    <div className="w-full h-[100dvh!important]  flex  px-5 pt-10">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {incomeDetails.length > 0 && (
        <Box className="w-[100%!important] h-[100%!important]">
          <DataGrid
            sx={{ backgroundColor: "white" }}
            rows={incomeDetails}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            className='bg-white dark:bg-darktheme border-0 text-darktheme dark:text-white w-full h-[80%!important]'
            />
        </Box>
      )}
    </div>
  );
}
