let statusIntitialState = {
    currentSlide: 0
}

export const carouselReducer = (state = statusIntitialState, action) => {
    switch (action.type) {
        case 'SET_CURRENT_SLIDE':
            return {
                ...state,
                currentSlide: action.payload,
            };


        default:
            return state;
    }
}