import React, { useState } from "react";
import { useSelector } from "react-redux";
import { changePasscodeService } from "../../services/AuthServices";
import "./ChangePassword.css";

import { ToastError, ToastSuccess } from "../common/Toast";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getTokenDetails = useSelector((state) => state.tokenReducer);

  const confirmButtonHandler = async () => {
    try {
      setIsLoading(true);
      if (oldPassword && newPassword && confirmNewPassword) {
        if (newPassword.length < 6) {
          ToastError("Password must be atleast 6 characters.");
          setIsLoading(false);
          return;
        }

        if (newPassword === confirmNewPassword) {
          let dataToSend = {};
          dataToSend.oldPassword = oldPassword;
          dataToSend.newPassword = newPassword;
          await changePasscodeService(dataToSend, getTokenDetails.token);
          setIsLoading(false);
          ToastSuccess("Password Changed Successfully.");
        } else {
          setIsLoading(false);
          ToastError("New passcode does not match.");
        }
      } else {
        setIsLoading(false);
        ToastError("Fill all required fields.");
      }
    } catch (error) {
      setIsLoading(false);
      ToastError("Failed To Change Passcode.");
    }
  };

  return (
    <div className="w-full h-full justify-center  items-center flex">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
     
      
  <div className="dark:bg-darktheme bg-white w-[600px] px-20 py-10 rounded-lg">
    <div className="w-full ">
      <div className="flex flex-col">
        <div>
          <h2 className="text-4xl text-black dark:text-white font-medium">Change passcode</h2>
        </div>
      </div>
      <form>
        <input value="https://jamstacker.studio/thankyou" type="hidden" name="_redirect"/>
        <div className="mt-4 space-y-6">
          <div className="col-span-full">
            <label className="block mb-3 text-sm font-medium dark:text-gray-300">Old Password   </label>
            <input type="password" placeholder="******" className="block w-full px-6 py-3 text-black bg-white border border-gray-200 rounded-md appearance-none placeholder:text-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
             onChange={(e)=>setOldPassword(e.target.value)}
             value={oldPassword}
            />
          </div>
          <div className="col-span-full">
            <label className="block mb-3 text-sm font-medium dark:text-gray-300"> New passord   </label>
            <input type="password" placeholder="******" className="block w-full px-6 py-3 text-black bg-white border border-gray-200 rounded-md appearance-none placeholder:text-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            value={newPassword}
            onChange={(e)=>setNewPassword(e.target.value)}
            />
          </div>
          <div className="col-span-full">
            <label className="block mb-3 text-sm font-medium dark:text-gray-300"> Confirm passord   </label>
            <input type="password" placeholder="******" className="block w-full px-6 py-3 text-black bg-white border border-gray-200 rounded-md appearance-none placeholder:text-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            value={confirmNewPassword}
            onChange={(e)=>setConfirmNewPassword(e.target.value)}
            />
          </div>

          <div className="col-span-full ">
            <button type="button" className="items-center justify-center w-full px-6 py-2.5 text-center text-white duration-200 bg-theme rounded-md" onClick={()=>confirmButtonHandler()}> Submit your request   </button>
          </div>
        </div>
      </form>
    </div>
  </div>

    </div>
  );
}
