import React from "react";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import './index.css';
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import { StyledEngineProvider } from "@mui/material";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>

    <Provider store={store}>
      <App />
    </Provider>

  </BrowserRouter>
);