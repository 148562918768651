import React, { useEffect, useState } from "react";

function UserPlanList() {
    const [userData, setUserData] = useState({});
 
  const getUserDetails = async () => {

    try {
      const response = localStorage.getItem("userDetails");
      setUserData(JSON.parse(response));
      console.log( setUserData(JSON.parse(response))  );

      console.log(response)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
              <h1> Plan list Taken</h1>

              

<div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left rtl:text-right text-blue-100 dark:text-blue-100">
        <thead class="text-xs text-white uppercase bg-blue-600 border-b border-blue-400 dark:text-white">
            <tr>
                <th scope="col" class="px-6 py-3 bg-grey-500">
                    S.no
                </th>
                <th scope="col" class="px-6 py-3 bg-blue-500">
                    Package
                </th>
                <th scope="col" class="px-6 py-3">
                    Date
                </th>
                <th scope="col" class="px-6 py-3 bg-blue-500">
                    ROI
                </th>
                <th scope="col" class="px-6 py-3">
                    Last Date 
                </th>
                <th scope="col" class="px-6 py-3 bg-blue-500">
                    Status
                </th>
            </tr>
        </thead>
        <tbody>

        </tbody>
    </table>
</div>

</>
  );
}

export default UserPlanList;

