import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Loader from "../components/common/Loader/Loader";
import { AppLayout } from "../components/common/AppLayout";
import Maindashboard from "../views/Maindashboard";
import Profile from "../components/common/Profile";
import ReferrList from "../components/common/ReferrList";
import DirectTeamList from "../components/common/DirectTeamList";
import DirectTeamListWithBussiness from "../components/common/DirectTeamListWithBussiness"
import BusinessHistory from "../components/common/BusinessHistory";
import TransactionHistory from "../components/common/TransactionHistory";
import MemberTree from "../components/common/MemberTree";
import DirectIncome from "../components/common/DirectIncome";
import LevelIncome from "../components/common/LevelIncome";
import ChangePassword from "../components/common/ChangePassword";
import UserPlanList from "../views/UserPlanList";
import UserReward from "../views/UserReward";
import PublicPage from "../views/PublicPage";
import "react-multi-carousel/lib/styles.css";

const Admin = lazy(() => import("../views/admin/Admin"));
const Checkstatus = lazy(() => import("../views/Checkstatus"));
const Dashboard = lazy(() => import("../views/Dashboard"));
const Deposit = lazy(() => import("../views/Deposit"));
const Homepage = lazy(() => import("../views/Homepage"));
const Investment = lazy(() => import("../views/Investment"));
const Investment1K = lazy(() => import("../views/Investment1K"));
const InvestmentStaking = lazy(() => import("../views/InvestmentStaking"));
const Login = lazy(() => import("../views/Login"));
const PageNotFound = lazy(() => import("../views/PageNotFound"));
const Payment = lazy(() => import("../views/Payment"));
const Register = lazy(() => import("../views/Register"));
const Resetpassword = lazy(() => import("../views/Resetpassword"));
const Rewards = lazy(() => import("../views/UserReward"));

export default function AppRoutes() {
  // states
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();
  const getTokenData = useSelector((state) => state.tokenReducer);

  // function to verify isUserLoggedIn
  const isUserLoggedIn = () => {
    try {
      // let token = getTokenData.token
      let token = localStorage.getItem("token");
      if (!token) {
        setIsLoggedIn(false);
      } else {
        setIsLoggedIn(true);
        dispatch({
          type: "SET_TOKEN",
          payload: token,
        });
      }
    } catch (error) {
      setIsLoggedIn(false);
      localStorage.clear();
    }
  };

  console.log(isLoggedIn);

  useEffect(() => {
    isUserLoggedIn();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Suspense
        fallback={
          // <div style={{
          //   width: '100%',
          //   height: '100vh',
          //   display: 'flex',
          //   justifyContent: 'center',
          //   alignItems: 'center'
          // }}>
          //   <Lottie animationData={loaderData} loop={true} />
          // </div>
          <Loader />
        }
      >
        {isLoggedIn && (
          <AppLayout>
            <Routes>
              <Route exact path="/" element={<Maindashboard />} />
              <Route exact path="/admin" element={<Admin />} />

              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/Profile" element={<Profile />} />
              <Route exact path="/teamList/referals" element={<ReferrList />} />
              <Route exact path="/teamList/list" element={<DirectTeamList />} />
              <Route exact path="/teamList/listwithbussiness" element={<DirectTeamListWithBussiness />} />

              <Route exact path="/profile" element={<Profile />} />

              <Route
                exact
                path="/businessHistory"
                element={<BusinessHistory />}
              />

              <Route
                exact
                path="/transactionHistory"
                element={<TransactionHistory />}
              />

              <Route exact path="/memberTree" element={<MemberTree />} />
              <Route exact path="/totalIncome" element={<BusinessHistory />} />
              <Route exact path="/directIncome" element={<DirectIncome />} />
              <Route exact path="/levelIncome" element={<LevelIncome />} />
              <Route exact path="/campaign" element={<UserReward />} />

              <Route
                exact
                path="/changePassword"
                element={<ChangePassword />}
              />

              {/* totalIncome */}
              {/* DirectIncome */}

              <Route
                exact
                path="/login"
                element={<Login setIsLoggedIn={setIsLoggedIn} />}
              />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/payment" element={<Payment />} />
              <Route exact path="/deposit" element={<Deposit />} />
              <Route exact path="/resetpassword" element={<Resetpassword />} />
              <Route exact path="/investment" element={<Investment />} />
              <Route exact path="/wealth" element={<Investment1K />} />
              <Route exact path="/staking" element={<InvestmentStaking />} />
              <Route exact path="/checkstatus" element={<Checkstatus />} />
              <Route exact path="/rewarda" element={<Rewards />} />

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </AppLayout>
        )}

        {!isLoggedIn && (
          <Routes>
            <Route exact path="/" element={<PublicPage />} />

            {/* <Route exact path="/" element={<Homepage />} /> */}
            <Route exact path="/checkstatus" element={<Checkstatus />} />
            <Route exact path="/cashcontrol" element={<Admin />} />
            <Route exact path="/investment" element={<Investment />} />
            <Route exact path="/wealth" element={<Investment1K />} />
            <Route exact path="/staking" element={<InvestmentStaking />} />
            <Route
              exact
              path="/login"
              element={<Login setIsLoggedIn={setIsLoggedIn} />}
            />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/payment" element={<Payment />} />
            <Route exact path="/resetpassword" element={<Resetpassword />} />

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        )}
      </Suspense>
    </>
  );
}
