import React from "react";
import blockchain from "../../assets/blockchain.png";
import exchange from "../../assets/exchange.png";
import swapping from "../../assets/swaping.png";
import imgLogo1 from "../../assets/logo1.png";
import imgLogo2 from "../../assets/logo2.png";
import imgLogo3 from "../../assets/logo3.png";
import imgLogo4 from "../../assets/logo4.png";
import { InfiniteSlider, InfiniteSliderO } from "./InfiniteSlider";


function Intro() {
  return (
    <div className="w-screen lg:w-[90vw] mx-auto">
      <IntroCard
        id={"zepgame"}
        title={"ZepXGame"}
        img={
          "https://t4.ftcdn.net/jpg/05/64/31/67/360_F_564316725_zE8llusnCk3Sfr9rdfKya6fV7BQbjfyV.jpg"
        }
        data={
          "ZepG is an innovative gaming platform where players can earn ZepX coins through gameplay and predictions. This exciting ecosystem combines the thrill of gaming with the opportunity to earn cryptocurrency, offering a unique experience where skill and strategy translate into real rewards. Engage in various games, predict outcomes, and accumulate ZepX coins to elevate your gaming journey."
        }
        imgLogo={imgLogo1}
      />
      <IntroCard
        id={"zepchain"}
        title={"ZepChain"}
        img={blockchain}
        data={
          "Introducing zepChain, a revolutionary blockchain platform poised to redefine the industry. Aspiring to the heights of BSC and other renowned blockchains, zepChain is focused on creating a unique, decentralized ecosystem. It promises enhanced security, scalability, and user-friendly features, making it an attractive choice for developers and blockchain enthusiasts alike."
        }
        imgLogo={imgLogo2}
      />
      <IntroCard
        id={"zepexchange"}
        title={"ZepXChange"}
        img={
          exchange
        }
        data={
            "ZepXchange, a comprehensive cryptocurrency exchange platform, offers a wide array of services including spot trading, futures, staking, and a secure wallet. Catering to both novice and experienced traders, it emphasizes user-friendly interfaces, robust security measures, and a diverse range of tradable digital assets."
          }
          imgLogo={imgLogo4}
      />
      <IntroCard
        id={"zepexswap"}
        title={"ZepSwap"}
        img={swapping}
        
        data={
            
            "ZepSwap is a user-friendly crypto swapping platform, enabling seamless and secure exchanges of various cryptocurrencies. With its intuitive interface, ZepSwap offers quick, efficient trading experiences, low transaction fees, and a diverse range of crypto pairs, catering to both beginners and seasoned crypto enthusiasts."
          }
          imgLogo={imgLogo3}
      />
      {/* <IntroCard title={'Introduction to ZepXGame'}/> */}
    </div>
  );
}

export default Intro;

const IntroCard = ({ title, img, id, data, imgLogo }) => {
  return (
    <div data-aos="fade-up" id={id} className="py-10 lg:py-20">
      <p className="gradientText capitalize text-center text-6xl font-semibold">
        {title}
      </p>

      <div className="flex flex-col lg:flex-row mt-10 lg:mt-20">
        <img
          className="border-orange-500 shadow-lg object-contain h-auto lg:h-[600px] w-full lg:w-[50%]"
          style={{
            clipPath:
            "polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)",
            filter: "grayscale(10%)",
          }}
          src={img}
        />

        <div className="lg:w-[50%] w-full flex justify-center items-center px-8">
          <div className="flex flex-col items-center text-center">
            <p className="text-white/80 text-lg lg:text-xl font-medium mt-10 lg:mt-0">
              {data}
            </p>
          <InfiniteSlider/>
            <img className="h-auto lg:h-[200px]" src={imgLogo} />{" "}
            {/* imgLogo goes here */}
          </div>
        </div>
      </div>
    </div>
  );
};
