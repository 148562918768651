import React from "react";
import newBg from "../../assets/newbg.jpg";
import animated from "../../assets/animated.png";
import mainavtar from "../../assets/mainavtar.png";

export default function Home() {
  return (
    <div
      id="home"
      className=" h-screen w-screen object-contain relative "
      style={{ background: `url(${newBg})`, backgroundSize: "cover" }}
    >
      <div
        data-aos="fade-up"
        className="flex items-center flex-wrap-reverse px-4 h-screen lg:px-16  w-screen"
      >
        <div className="  flex-col font-semibold  flex items-start  justify-start w-[95vw] lg:w-2/4 bg-black bg-opacity-50" style={{ padding: '20px', borderRadius: '35px',boxShadow:'0px 0px 40px 0px white',zIndex:'1' }}>
          <p className="hidden lg:block text-white leading-tight text-7xl" > 
            <span class="gradientText" >Generate</span> Your <br /> Passive
            Income <br /> with <span class="gradientText">Zepex</span>
          </p>

          <p className="block lg:hidden text-white leading-tight text-5xl">
            <span class="gradientText">Generate</span> Your Passive Income with{" "}
            <span class="gradientText">Zepex</span>
          </p>
          <p className="text-white text-2xl font-medium mt-6 z-30">
            Diversify your investment portfolio with rational investment
            choices. Choose multiple revenue-generating instruments and earn the
            highest possible returns.
          </p>
        </div>

        <img
          className=" h-auto lg:h-[80vh]  lg:right-0 delay-500  absolute -right-20 bottom-0 animate-spin duration-300	"
          src={animated}
        />

        <img
          className="h-auto lg:h-[80vh]  lg:right-36 delay-500  absolute right-8 bottom-0  "
          src={mainavtar}
        />
      </div>
    </div>
  );
}
