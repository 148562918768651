import React from 'react'
import newBg from "../../assets/Mask.png";

function Footre() {
    
  return (
    <div
    className='h-[80vh] relative flex justify-center items-center flex-col'
    style={{ background: `url(${newBg})`, backgroundSize: "cover" }}
    >

        <p className='text-3xl z-10 lg:text-5xl text-white font-semibold mb-4 shadow-lg '>To get latest updates</p>
        <p className=' text-4xl z-10 lg:text-8xl font-semibold text-white'>Join ZEPX Community</p>

    <div className='h-full w-full absolute bg-black/40'></div>
    </div>
  )
}

export default Footre