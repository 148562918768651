import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { getAllTeamMemberWithBussinessService } from '../../services/userServices';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Pagination } from '@mui/material';

const columns = [
    {
        field: 'sl',
        headerName: 'Sr. No.',
        width: 60
    },
    {
        field: 'name',
        headerName: 'Team Member',
        width: 100,
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 250,
    },
    {
        field: 'Level',
        headerName: 'Level',
        width: 90,
    },
    {   
        field: 'monthlyBusiness',
        headerName: 'Monthly Business',
        width: 120,
    },
    {
        field: 'totalBusiness',
        headerName: 'Total Business',
        width: 120,
    },
    {
        field : 'teamMonthlyBusiness',
        headerName: 'Team Monthly Business',
        width: 160,
    },
    {
        field : 'teamTotalBusiness',
        headerName: 'Team Total Business',
        width: 160,
    }
];

export default function DirectTeamListWithBussiness() {
    const getTokenData = useSelector(state => state.tokenReducer)
    const [isLoading, setIsLoading] = useState(false);
    const [incomeDetails, setIncomeDetails] = useState([])

const getIncomeDetails = async () => {
    try {
        setIsLoading(true);

        // Retrieve user details from local storage
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        const userId = userDetails ? userDetails._id : null;

        if (!userId) {
            console.error("User ID not found in local storage");
            setIsLoading(false);
            return;
        }

        // Make the API call with the retrieved userId
        let res = await getAllTeamMemberWithBussinessService({
            userid: userId,

        }, getTokenData.token);

        let temp = [];
        res.data.result.map((d, i) => {
            let email = d.Email.toString();
            if (email !== "N/A") {
                const [localPart, domain] = email.split("@");
                const maskedLocalPart = `${localPart.slice(0, 3)}XXXX${localPart.slice(-2)}`;
                email = `${maskedLocalPart}@${domain}`;
            }

            // Check and update level
            let level = d.Level;
            if (level === 'N/A') {
                level = "Own ID";
            }

            // If both level and email are "N/A", set both to "Own ID"
            if (d.Level === 'N/A' && d.Email === 'N/A') {
                level = "Own ID";
                email = "Own ID";
            }

            temp.push({
                id: i + 1,  // 'id' is required by DataGrid
                sl: (i + 1).toString(),
                name: d.Name.toString(),
                Level: level,
                email: email,
                monthlyBusiness: d["Monthly Business"],
                totalBusiness: d["Total Business"],
                teamMonthlyBusiness: d["Team Monthly Business"],
                teamTotalBusiness: d["Team Total Business"],
            });
            return d;
        });
        setIncomeDetails([...temp]);
        setIsLoading(false);
    } catch (error) {
        setIsLoading(false);
        console.error("Failed to fetch income details", error);
    }
}


    useEffect(() => {
        getIncomeDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function CustomPagination() {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);
      
        return (
          <Pagination
            color="primary"
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
          />
        );
    }

    return (
        <div className='w-full h-[100dvh!important] flex px-5 mt-10'>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {incomeDetails.length > 0 && 
            <Box className='w-full h-[100%!important]'>
                <DataGrid 
                    rows={incomeDetails}
                    columns={columns}
                    pageSize={15}
                    rowsPerPageOptions={[15]}
                    className='bg-white dark:bg-darktheme border-0 text-darktheme dark:text-white w-full h-[80%!important]'
                    components={{
                        Pagination: CustomPagination,
                    }}
                />
            </Box>
            }
        </div>
    );
}
