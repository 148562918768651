import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import './LevelIncome.css'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getLevelWiseIncomeDetailsService } from '../../services/AuthServices';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from 'react';

const columns = [
    {
        field: 'id',
        headerName: 'SL',
        width: 90
    },
    {
        field: 'ref',
        headerName: 'Member Id',
        width: 150,
    },
    {
        field: 'plan',
        headerName: 'Plan Amount',
        width: 150,
    },
    {
        field: 'commision',
        headerName: 'Commision',
        width: 110,
    },
    {
        field: 'level',
        headerName: 'Level',
        width: 110,
    },
    {
        field: 'commisionType',
        headerName: 'Commission Type',
        width: 150,
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 200
    },
];

export default function LevelIncome() {

    const getTokenData = useSelector(state => state.tokenReducer)
    const [isLoading, setIsLoading] = useState(false);

    const [selectedLevel, setSelectedLevel] = React.useState(1)

    const [incomeDetails, setIncomeDetails] = React.useState([])

    const getCommisionTypeByValue = (type) => {
        switch (type) {
            case 1: return 'For New Joining'
            case 2: return 'For AutoTopup'
            case 3: return 'For Manual Topup'
            case 4: return 'For ZepX Referral'
            default: return ''
        }
    }

    const getIncomeDetails = async () => {
        try {
            setIsLoading(true)
            let res = await getLevelWiseIncomeDetailsService(selectedLevel, getTokenData.token)
            let temp = []
            res.data.result.map((d, i) => {
                temp.push({
                    id: (i + 1).toString(),
                    ref: d.newMemberReferralCode ? (d.newMemberReferralCode).toString() : '-',
                    plan: (d.totalAmount).toString(),
                    commision: (d.commisionAmount.toString()),
                    level: d.commisionLevel,
                    commisionType: d?.commisionType ? getCommisionTypeByValue(d.commisionType) : getCommisionTypeByValue(1),
                    date: new Date(d.timeStamp)
                })
                return d
            })
            setIncomeDetails([...temp])
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getIncomeDetails()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLevel])


    return (
        <div className='w-full h-[100dvh!important]  flex flex-col px-5'>
             <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>


            <div className='selectBoxDiv'>
                <>
                    <FormControl style={{ width: 200, backgroundColor: 'white' }}>
                        <InputLabel id="demo-simple-select-label">Level</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedLevel}
                            label="Age"
                            onChange={(e) => { setSelectedLevel(e.target.value) }}
                        >
                            <MenuItem value={'ALL'}>ALL</MenuItem>
                            <MenuItem value={1}>Level 1</MenuItem>
                            <MenuItem value={2}>Level 2</MenuItem>
                            <MenuItem value={3}>Level 3</MenuItem>
                            <MenuItem value={4}>Level 4</MenuItem>
                            <MenuItem value={5}>Level 5</MenuItem>
                            <MenuItem value={6}>Level 6</MenuItem>
                            <MenuItem value={7}>Level 7</MenuItem>
                            <MenuItem value={8}>Level 8</MenuItem>
                            <MenuItem value={9}>Level 9</MenuItem>
                            <MenuItem value={10}>Level 10</MenuItem>
                            <MenuItem value={11}>Level 11</MenuItem>
                            <MenuItem value={12}>Level 12</MenuItem>
                            <MenuItem value={13}>Level 13</MenuItem>
                            <MenuItem value={14}>Level 14</MenuItem>
                            <MenuItem value={15}>Level 15</MenuItem>
                        </Select>
                    </FormControl>
                </>
            </div>


            {incomeDetails.length > 0 && <Box className='w-full h-[100%!important]'>
                <DataGrid sx={{ backgroundColor: 'white' }}
                    rows={incomeDetails}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    className='bg-white dark:bg-darktheme border-0 text-darktheme dark:text-white w-full h-[80%!important]'
                // checkboxSelection
                // disableSelectionOnClick
                // experimentalFeatures={{ newEditingApi: true }}
                />
            </Box>}
        </div>
    );
}
