import { NavBar } from "./Navbar";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { UserSideBar } from "./UserSideBar";

export const AppLayout = ({ children }) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      // Check the viewport width and update the state accordingly
      setOpen(window.innerWidth >= 1024 ? true : false);
    };

    // Initial check on mount
    handleResize();

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); //

  return (
    <>
      {/* <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: { background: "#61d345", color: "white" },
            iconTheme: { primary: "#27D05A" },
          },
          error: {
            style: { background: "#D03D27", color: "white" },
          },
        }}
      /> */}
      {/* <NavBar close={() => setOpen(!open)} /> */}
      <UserSideBar open={open} setOpen={setOpen}>
        <div className="w-full  h-screen bg-bgTheme relative">{children}</div>
      </UserSideBar>
    </>
  );
};
