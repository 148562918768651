import { Backdrop, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Tree from 'react-d3-tree';
import { useSelector } from 'react-redux';
import { getMemberTreeService } from '../../services/userServices';
import { ToastError } from './Toast';
import './MemberTree.css'
import { useCenteredTree } from './MemberTreeHelper';

export default function MemberTree() {

    const getTokenData = useSelector(state => state.tokenReducer)
    const [memberTree, setMemberTree] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [dimensions, translate, containerRef] = useCenteredTree();

    const getMemberTree = async () => {
        try {
            setIsLoading(true)
            let result = await getMemberTreeService(getTokenData.token)
            setMemberTree(result.data.result)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            ToastError('Failed To Fetch.')
        }
    }

    useEffect(() => {
        getMemberTree()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const containerStyles = {
        width: "100%!important",
        height: "100vh",
        marginLeft: 10
    };

    return (
        <div className='w-full px-20 flex items-center '>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div style={containerStyles} ref={containerRef}>
                <h6 style={{ marginTop: 20, marginBottom: 20 }}>Note: Click on circle to expand/collapse the node. You can also drag the tree for proper view. You can also zoom in/out the tree.</h6>
                <Tree
                    data={memberTree}
                    dimensions={dimensions}
                    translate={translate}
                    zoomable={true}
                    orientation="vertical"
                    pathFunc={'step'}
                    rootNodeClassName="node__root"
                    branchNodeClassName="node__branch"
                    leafNodeClassName="node__leaf"
                />
            </div>
        </div>

    )
}
